import React, { useEffect, useMemo, useState } from 'react';
import { Flex, Form, Select, Tag } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import TagsService from 'activities/services/Tags.service';
import intl from 'i18n/intl';
import { v4 } from 'uuid';
import { FormItem, FormItemController } from '../BaseElements/TEFormItem';
import { TActivityRawValueOption } from 'activities/pages/types/activity.type';

const language = intl.messages as Record<string, string>;

function ButtonAddingNewTag({
  formId,
  value,
  onSelect,
}: {
  formId: string;
  value: TActivityRawValueOption[];
  onSelect: (tag: TActivityRawValueOption) => void;
}) {
  const [inputShown, setInputShown] = useState(false);
  const [options, setOptions] = useState<TActivityRawValueOption[]>([]);

  const selectedTags = useMemo(() => {
    return value.map((tag) => tag.value);
  }, [value]);

  useEffect(() => {
    const doGettingTags = async () => {
      const tags = await TagsService.getTagsInForm(formId);
      setOptions(
        Object.keys(tags).map((tagId) => ({
          value: tagId,
          label: tags[tagId].name,
        })),
      );
    };
    doGettingTags();
  }, []);

  const onSelectCallback = (value: string, option: TActivityRawValueOption) => {
    if (!option.value) {
      const newOption = {
        value: v4(),
        label: value,
        isNew: true,
      };
      setOptions([...options, newOption]);
      onSelect(newOption);
    } else {
      onSelect(option);
    }
    setInputShown(false);
  };

  if (inputShown) {
    return (
      <Select
        mode="tags"
        size="small"
        placeholder={language.search_or_create_tag}
        options={options.filter((item) => !selectedTags.includes(item.value))}
        onSelect={onSelectCallback}
        onBlur={() => setInputShown(false)}
        autoFocus
        style={{ width: 200 }}
      />
    );
  }
  return (
    <Tag onClick={() => setInputShown(true)}>
      <PlusOutlined /> {language.new_tag}
    </Tag>
  );
}

function ActivityTagsContent({
  value,
  onChange,
  formId,
  editable,
}: {
  value: TActivityRawValueOption[];
  onChange: any;
  formId: string;
  editable?: boolean;
}) {
  const onSelect = (tag: TActivityRawValueOption) => {
    if (value.some((val) => val.value === tag.value)) {
      onChange(value.filter((val) => val.value !== tag.value));
    } else {
      onChange([...value, tag]);
    }
  };

  return (
    <Flex gap={8} vertical>
      <div>
        {(value || []).map((item: any) => (
          <Tag key={item.value} onClose={() => onSelect(item)} closable={editable}>
            {item.label}
          </Tag>
        ))}
      </div>
      {editable && (
        <div>
          <ButtonAddingNewTag formId={formId} onSelect={onSelect} value={value} />
        </div>
      )}
    </Flex>
  );
}

export default function ActivityTags({ formId, editable }: { formId?: string; editable?: boolean }) {
  if (!formId) return null;
  return (
    <FormItem label={language.tags}>
      <FormItemController name="tags">
        {({ value, onChange }) => (
          <ActivityTagsContent
            editable={editable}
            formId={formId}
            value={(value as unknown as TActivityRawValueOption[]) || []}
            onChange={onChange}
          />
        )}
      </FormItemController>
    </FormItem>
  );
}
