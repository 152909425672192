/* eslint-disable import/no-extraneous-dependencies */
import api from '../../services/api.service';
import { EActivityVisibility } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityVisibility.type';
import { EActivityGroupings } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityGroupings.enum';
import {
  TActivity2Be,
  TActivity2Fe,
} from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/Activity2.type';
import XLSX from 'xlsx';
import { configService } from '../../services/config.service';
import { castArray, compact, isEmpty, keyBy, omit, pick, set } from 'lodash';
import { notification } from 'antd';
import { TTEReservationWithExtIds } from '../../types/teReservation.type';
import intl from '../../i18n/intl';
import { TImportTemplate } from 'activities/pages/types/importTemplate.type';
import { TActivityGroupBy, ActivityState } from 'activities/pages/slices/activity.slice';
import dayjs, { Dayjs } from 'dayjs';
import { getValueFromFormItem } from 'activities/components/BaseElements/TEFormItem';
import {
  TActivityRawValueOption,
  TActivityResultsInResponseGroupByActivitySeries,
  TActivityStartEndDate,
} from 'activities/pages/types/activity.type';
import TagsService from './Tags.service';
import { TExactSearchField } from '@timeedit/ui-components/lib/src/components/ObjectFilter/ObjectFilter.type';
import TEObjectsService from './TEObjects.service';
import { SUBMISSION_VALUE_TYPE } from '@timeedit/activity-manager-shared-lib/lib/internal/types/schedulingEnum.type';
import {
  toActivityWeekRange,
  toCenterOfWeek,
  toDurationDisplay,
  toWeeksDisplay,
  toSpecificTimezone,
} from 'activities/utils';
import { ActivityElementValue } from '@timeedit/types/lib';
import { TActivityValue } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityValue.type';
import { TWeekSelectorValue } from 'activities/components/WeekSelector/WeekSelector';
import { weekRangeChar } from 'activities/components/Table/ActivitiesTable.utils';
import { EActivityStatus } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityStatus.enum';

const language = intl.messages as Record<string, string>;

export const SHEET_NAMES = {
  information: 'Information',
  templateId: 'Template ID',
};

const propsToQuery = (props: Object) => {
  const finalQueryObject = JSON.stringify(props);
  const urlParams = new URLSearchParams({ ssp: finalQueryObject });
  return urlParams.toString();
};

export const getActivities = (organizationId: string, props: Object /* Should be more tricky */) => {
  const query = propsToQuery(props);
  return api.post({
    successMessage: false,
    endpoint: `${configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL}v1/organization/${organizationId}/activities`,
    data: {
      query,
      visibility: EActivityVisibility.DRAFT,
    },
  });
};

export const getTracksInSeries = (organizationId: string, seriesId: string) => {
  return api.get({
    successMessage: false,
    endpoint: `${configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL}v1/organization/${organizationId}/activities/activity-series/${seriesId}/tracks`,
  });
};

export const deleteActivities = (
  organizationId: string,
  activitySeriesIds: string[],
  activityGroupBy = EActivityGroupings.ACTIVITY_SERIES,
) => {
  return api.post({
    successToastTitle: language['activities.overview.table.message.delete_activites_in_progress.title'],
    successToastType: 'info',
    successMessage: language['activities.overview.table.message.delete_activites_in_progress.message'],
    endpoint: `${
      configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL
    }v1/organization/${organizationId}/activities/batch-operation/delete`,
    data: {
      data: activitySeriesIds,
      idKind: activityGroupBy,
    },
  });
};

export const publishActivities = (
  organizationId: string,
  ids: string[],
  activityGroupBy = EActivityGroupings.ACTIVITY_SERIES,
) => {
  return api.post({
    successToastTitle: language['activities.overview.table.message.publish_activites_in_progress.title'],
    successToastType: 'info',
    successMessage: language['activities.overview.table.message.publish_activites_in_progress.message'],
    endpoint: `${
      configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL
    }v1/organization/${organizationId}/activities/batch-operation/publish`,
    data: {
      data: ids,
      idKind: activityGroupBy,
    },
  });
};

export type TImportData = {
  activityImportId: string;
  file: File;
  template: TImportTemplate;
  owner: string;
  name: string;
  description: string;
};
export const importActivities = async (organizationId: string, importData: TImportData) => {
  try {
    const { template, owner, name, description, file, activityImportId: _activityImportId } = importData;
    const newActivityImport = {
      name,
      description,
      owner,
      activityCreateTemplateId: template._id,
    };

    let activityImportId = _activityImportId;
    if (!activityImportId) {
      const activityImportResponse = await api.post({
        successMessage: false,
        endpoint: `${configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL}v1/organization/${
          organizationId
        }/activity-imports`,
        data: {
          activityImport: newActivityImport,
        },
      });
      activityImportId = activityImportResponse._id;
    }
    const formData = new FormData();

    formData.append('csv', file);
    formData.append('activityImportId', activityImportId);
    formData.append('separator', ',');

    const results = await api.post({
      headers: {
        withCredentials: false,
        'Access-Control-Allow-Credentials': true,
        'Access-Control-Allow-Origin': true,
      },
      successMessage: false,
      errorMessage: false,
      endpoint: `${configService().REACT_APP_NODE_CSV_URL}api/import`,
      data: formData,
    });
    return results;
  } catch (err) {
    return err;
  }
};

export const fetchActivityImportTemplate = (organizationId: string) => {
  return api.get({
    successMessage: false,
    endpoint: `${
      configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL
    }v1/organization/${organizationId}/activity-create-template`,
  });
};

export const createActivityImportTemplate = (template: TImportTemplate, organizationId: string) => {
  return api.post({
    successMessage: false,
    endpoint: `${configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL}v1/organization/${
      organizationId
    }/activity-create-template`,
    data: {
      activityCreateTemplate: template,
    },
  });
};

export const deleteActivityImportTemplate = (templateId: string, organizationId: string) => {
  return api.delete({
    successMessage: true,
    endpoint: `${configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL}v1/organization/${
      organizationId
    }/activity-create-template/${templateId}`,
  });
};

export const downloadActivityImportTemplate = async (
  templateId: string,
  templateName: string,
  includeTracks: boolean,
) => {
  const separator = ';';
  const params = new URLSearchParams();
  params.append('template', templateId);
  params.append('includeTracksColumn', String(includeTracks));
  params.append('separator', separator);

  try {
    const res: string = await api.post({
      successMessage: false,
      endpoint: `${configService().REACT_APP_NODE_CSV_URL}api/createTemplate`,
      data: params,
    });
    const rows = res.split('\n').map((row) => row.split(separator));

    const columnsToRemove = ['Timezone'];
    const timezoneIndexes = compact(
      rows[0].map((title, titleIdx) => {
        if (columnsToRemove.includes(title)) return titleIdx;
        return null;
      }),
    );
    const rowsWithoutTimezone = rows
      .map((cells) => cells.filter((cell, idx) => !timezoneIndexes.includes(idx)))
      .join('\n');
    const workbook = XLSX.read(rowsWithoutTimezone, { type: 'string' });
    XLSX.writeFile(workbook, `${templateName}_${templateId}.csv`);
  } catch (e) {
    console.error(e);
    notification.error({
      duration: 0,
      key: configService().NOTIFICATION_KEY,
      message: 'Error downloading template',
      description: '',
    });
  }
};

export const triggerDownloadActivities = async (
  organizationId: string,
  selectedRowKeys: string[],
  groupBy: TActivityGroupBy,
) => {
  try {
    let filterPropertyName: 'activitySeriesId' | 'id';
    if (groupBy === 'ACTIVITY_SERIES') {
      filterPropertyName = 'activitySeriesId';
    } else if (groupBy === 'FLAT') {
      filterPropertyName = 'id';
    } else {
      throw new Error(`groupBy value "${groupBy}" not implemented in triggerDownloadActivities`);
    }

    const props = { filters: { [filterPropertyName]: { values: selectedRowKeys } } };
    const query = propsToQuery(props);

    await api.post({
      successMessage: false,
      endpoint: `${
        configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL
      }v1/organization/${organizationId}/activities/export-activities-by-query`,
      data: {
        visibility: EActivityVisibility.DRAFT,
        query,
      },
    });
    notification.success({
      key: configService().NOTIFICATION_KEY,
      message: language['activities.export.started'],
      description: language['activities.export.started_details'],
      duration: 0,
    });
  } catch (e) {
    console.error(e);
    notification.error({
      duration: 0,
      key: configService().NOTIFICATION_KEY,
      message: language['activities.export.error'],
      description: '',
    });
  }
};

export const getActivityLookupMap = async (organizationId: string, { date }: ActivityState['filter']) => {
  return api.post({
    successMessage: false,
    endpoint: `${
      configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL
    }v1/organization/${organizationId}/activities/filters`,
    data: {
      visibility: EActivityVisibility.DRAFT,
      query: {
        date: {
          values: date,
        },
      },
    },
  });
};

export const getActivityMandatoryFields = async (
  organizationId: string,
  { date }: ActivityState['filter'],
): Promise<Record<string, string[]>> => {
  return api.post({
    successMessage: false,
    endpoint: `${
      configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL
    }v1/organization/${organizationId}/activities/mandatory-fields`,
    data: {
      visibility: EActivityVisibility.DRAFT,
      query: {
        date: {
          values: date,
        },
      },
    },
  });
};

export const getActivityLookupLeaf = async (
  organizationId: string,
  { date }: ActivityState['filter'],
  field: string,
  query?: Record<string, string>,
) => {
  return api.post({
    successMessage: false,
    endpoint: `${
      configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL
    }v1/organization/${organizationId}/activities/filters/object-key/${field}`,
    params: query,
    data: {
      visibility: EActivityVisibility.DRAFT,
      query: {
        date: {
          values: date,
        },
      },
    },
  });
};

const exportReservationsWithExtIds = (reservationMode: number, startDate: number, endDate: number) => {
  return api.post({
    successMessage: false,
    endpoint: `${configService().REACT_APP_PATHWAY_SERVICE_URL}v1/reservation-export/find`,
    data: {
      startDate,
      endDate,
      reservationMode,
    },
  });
};

type TDownloadActivityImportTemplateWithReservationsArg = {
  reservationMode: number;
  startDate: number;
  endDate: number;
  activityCreateTemplateId: string;
  templateName: string;
};

export const downloadActivityImportTemplateWithReservations = async ({
  reservationMode,
  startDate,
  endDate,
  activityCreateTemplateId,
  templateName,
}: TDownloadActivityImportTemplateWithReservationsArg) => {
  try {
    const { data } = await exportReservationsWithExtIds(reservationMode, startDate, endDate);
    const reservationsWithExtIds: TTEReservationWithExtIds = data.reservations;

    const separator = ';';
    const params = new URLSearchParams();
    params.append('template', activityCreateTemplateId);
    params.append('separator', separator);
    params.append('reservations', JSON.stringify(reservationsWithExtIds));

    const res: string = await api.post({
      successMessage: false,
      endpoint: `${configService().REACT_APP_NODE_CSV_URL}api/createTemplateWithReservations`,
      data: params,
    });
    const rows = res.split('\n').map((row) => row.split(separator));

    const columnsToRemove = ['Timezone'];
    const timezoneIndexes = compact(
      rows[0].map((title, titleIdx) => {
        if (columnsToRemove.includes(title)) return titleIdx;
        return null;
      }),
    );
    const rowsWithoutTimezone = rows
      .map((cells) => cells.filter((cell, idx) => !timezoneIndexes.includes(idx)))
      .join('\n');
    const workbook = XLSX.read(rowsWithoutTimezone, { type: 'string' });
    XLSX.writeFile(workbook, `${templateName}_${activityCreateTemplateId}.csv`);

    notification.success({
      key: configService().NOTIFICATION_KEY,
      message: 'Reservation export complete',
    });
  } catch (e) {
    console.error(e);
    notification.error({
      duration: 0,
      key: configService().NOTIFICATION_KEY,
      message: 'Error downloading template',
      description: '',
    });
  }
};

export const fetchActivityImports = async (organizationId: string) => {
  return api.get({
    endpoint: `${
      configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL
    }v1/organization/${organizationId}/activity-imports`,
  });
};

export const getFormNameByIds = async (
  organizationId: string,
  activityImportIds: string[],
): Promise<{ _id: string; activityCreateTemplateName: string }[]> => {
  return api.post({
    endpoint: `${
      configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL
    }v1/organization/${organizationId}/activity-imports/get-form-name-by-ids`,
    data: {
      activityImportIds,
    },
    successMessage: false,
  });
};

export const fetchActivityImportAttemptResult = async (organizationId: string, activityImportAttemptId: string) => {
  return api.get({
    successMessage: false,
    errorMessage: false,
    endpoint: `${configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL}v1/organization/${organizationId}/activity-imports/result/${activityImportAttemptId}`,
  });
};

export const generateForm = async (
  activityCreateTemplateId: string,
  settings: { dueDate: string; formPeriod: { startDate: string; endDate: string } },
) => {
  return api.post({
    endpoint: `${configService().REACT_APP_PREFERENCES_URL}v1/forms/generate`,
    data: {
      activityCreateTemplateId,
      ...settings,
    },
  });
};

export const generateFormInstances = async (organizationId: string, activitySeriesIds: string[]) => {
  return api.post({
    endpoint: `${configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL}v1/organization/${organizationId}/activities/batch-operation/review`,
    data: {
      activitySeriesIds,
    },
  });
};
export const getActivityFormInstancesStatus = async (formInstanceIds: string[]) => {
  return api.get({
    endpoint: `${configService().REACT_APP_PREFERENCES_URL}v1/form-instances/status`,
    data: {
      formInstanceIds,
    },
  });
};

export const getActivityById = async (organizationId: string, activityId: string) => {
  return api.get({
    successMessage: false,
    endpoint: `${configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL}v1/organization/${organizationId}/activities/${activityId}`,
  });
};

export const addNewTrackToActivitySeries = async (
  {
    organizationId,
    activitySeries,
  }: { organizationId: string; activitySeries: TActivityResultsInResponseGroupByActivitySeries },
  values: Record<string, any>,
) => {
  const nestedValues = toNestedObject(values);
  const activityValues = Object.keys(nestedValues)
    // @TODO: No weeks and duration for now
    .filter((key) => !['weeks', 'duration', 'numberOfTracks'].includes(key))
    .map((key) => {
      let activityValue = toActivityUpdateValue(getValueFromFormItem(nestedValues[key]));
      if (!activityValue?.categories && !Array.isArray(activityValue)) activityValue = [];
      return {
        extId: key,
        value: activityValue,
      };
    });

  return api.post({
    endpoint: `${configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL}v1/organization/${organizationId}/activities/add-tracks-to-activity-series`,
    data: {
      activitySeriesId: activitySeries.activitySeriesId,
      sourceId: activitySeries.sourceId,
      numberOfTracksToAdd: nestedValues.numberOfTracks,
      activityValues,
    },
  });
};

export const duplicateTrack = async ({
  organizationId,
  activitySeries,
  trackId,
}: {
  organizationId: string;
  activitySeries: TActivityResultsInResponseGroupByActivitySeries;
  trackId: number;
}) => {
  return api.post({
    endpoint: `${configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL}v1/organization/${organizationId}/activities/add-tracks-to-activity-series`,
    data: {
      activitySeriesId: activitySeries.activitySeriesId,
      sourceId: activitySeries.sourceId,
      numberOfTracksToAdd: 1,
      numberOfTrackToUseAsTemplate: trackId,
    },
  });
};

export const updateActivitiesValues = async ({
  formId,
  ids,
  groupedBy,
  values,
  timezone,
}: {
  formId: string;
  ids: string[];
  groupedBy: TActivityGroupBy;
  values: Record<string, string>;
  timezone?: string;
}) => {
  const timingFields = ['duration', 'dateRanges'];
  const timing = pick(values, timingFields);

  const { tags } = values;
  const otherValues = omit(values, [...timingFields, 'tags', 'weeks']);
  try {
    if (!isEmpty(timing)) {
      await api.post({
        successMessage: isEmpty(otherValues),
        endpoint: `${configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL}v1/forms/${formId}/activities/batch-operations/timing`,
        data: {
          type: 'TIMING',
          idKind: groupedBy,
          data: ids.flatMap((id) => {
            return Object.keys(timing)
              .map((key) => {
                const formattedValues = getValueFromFormItem(values[key]);
                const basePayload: Record<string, string | string[] | { startTime: string; endTime: string } | null> = {
                  _id: id,
                  valueType: 'timing',
                  extId: key,
                  opsType: 'SET',
                };
                if (key === 'duration') {
                  basePayload.payload = formattedValues.toString();
                  basePayload.extId = 'length';
                } else if (key === 'dateRanges') {
                  const weeks = castArray(formattedValues?.[0]);
                  if (!weeks || !weeks[0]) return null;
                  basePayload.extId = 'dateRanges';
                  basePayload.payload = {
                    startTime: toSpecificTimezone(weeks[0], timezone).startOf('week').toJSON(),
                    endTime: toSpecificTimezone(weeks[weeks.length - 1], timezone)
                      .endOf('week')
                      .toJSON(),
                  };
                }
                return basePayload;
              })
              .filter((item) => item && item.payload);
          }),
        },
      });
    }

    if (!isEmpty(otherValues)) {
      const nested = toNestedObject(otherValues);
      const updateValuePayload = ids.flatMap((id) => {
        return Object.keys(nested).map((key) => {
          const value = getValueFromFormItem(nested[key]);
          const payloadValue = toActivityUpdateValue(value);
          return {
            _id: id,
            valueType: 'values',
            extId: key,
            opsType: 'SET',
            payload: payloadValue,
          };
        });
      });
      if (updateValuePayload.length) {
        await api.post({
          successMessage: true,
          endpoint: `${configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL}v1/forms/${formId}/activities/batch-operations/values`,
          data: {
            type: 'VALUES',
            idKind: groupedBy,
            data: updateValuePayload,
          },
        });
      }
    }

    if (tags) {
      const formattedTags: TActivityRawValueOption[] = getValueFromFormItem(tags);
      const tagIds = await Promise.all(
        formattedTags.map(async (item) => {
          if (!item.isNew) return item.value;
          const newTag = await api.post({
            successMessage: true,
            endpoint: `${configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL}v1/forms/${formId}/tags`,
            data: {
              name: item.label,
            },
          });

          // Delete current tags in form, to make sure they will be reloaded in next session
          TagsService.tagsByFormId[formId] = [];
          return newTag._id;
        }),
      );
      await api.post({
        successMessage: true,
        endpoint: `${configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL}v1/forms/${formId}/activities/batch-operations/tags`,
        data: {
          type: 'TAGS',
          idKind: groupedBy,
          data: ids.map((id) => {
            return {
              _id: id,
              tagIds,
              opsType: 'SET',
            };
          }),
        },
      });
    }
  } catch (err) {
    console.log('Error when saving activities: ', err);
  }
};

export const deleteActivitiesById = async (organizationId: string, activityIds: string[]) => {
  return api.post({
    successMessage: language.activity_deleted_notification,
    endpoint: `${configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL}v1/organization/${organizationId}/activities/delete-activities-by-id`,
    data: {
      activityIds,
    },
  });
};

const toWeeksString = (weeks: TWeekSelectorValue): string => {
  if (Array.isArray(weeks)) {
    const sorted = weeks.sort();
    return `(${[toWeeksString(sorted[0]), toWeeksString(sorted[sorted.length - 1])].join(weekRangeChar)})`;
  }
  return dayjs(weeks).format('YYWW');
};

export const updateActivitySeriesWeeks = async (
  organizationId: string,
  activitySeriesId: string,
  selectedWeeks: TWeekSelectorValue[],
) => {
  const weeks = selectedWeeks.map(toWeeksString);
  return api.put({
    successMessage: true,
    endpoint: `${configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL}v1/organization/${organizationId}/activity-series/${activitySeriesId}/weeks`,
    data: {
      weeks,
    },
  });
};

export const updateActivitySeriesTrackWeeks = async (
  organizationId: string,
  activitySeriesId: string,
  trackId: number,
  selectedWeeks: TWeekSelectorValue[],
) => {
  const weeks = selectedWeeks.map(toWeeksString);
  return api.put({
    successMessage: true,
    endpoint: `${configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL}v1/organization/${organizationId}/activity-series/${activitySeriesId}/track/${trackId}/weeks`,
    data: {
      weeks,
    },
  });
};
class TEActivitiesManager {
  organizationId: string;

  activities: Record<string, any>;

  constructor() {
    this.organizationId = '';
    this.activities = {};
  }

  init(data: any) {
    this.organizationId = data.organizationId;
  }

  async getActivities({ sourceId, primaryObject }: { sourceId: string[]; primaryObject: string[] }) {
    const response = await getActivities(this.organizationId, {
      filters: {
        sourceId: {
          values: sourceId,
        },
        primaryObject: {
          values: primaryObject,
        },
      },
      limit: -1,
    });
    return response.results as TActivity2Be[];
  }
}

const toFilterOperator = (values: string | string[]) => (Array.isArray(values) ? 'in' : 'eq');

export const isEmptyValue = (value: any) => {
  if (typeof value === 'object' && isEmpty(value)) return true;
  return !castArray(value).filter((item) => ![undefined, null, ''].includes(item))?.length;
};

export const objectToKey = (input: string | { category?: string; field?: string; key: string }) => {
  if (typeof input === 'string') return input;
  if (!input.category && !input.field) return input.key;
  return JSON.stringify(input);
};

export const keyToObject = (key: string) => {
  try {
    return JSON.parse(key);
  } catch {
    return { key };
  }
};

export const toActivityUpdateValue = (value: Record<string, any>) => {
  if (!value) return '';
  if (value?.filters) {
    if (!value.value?.length && value.valueType === SUBMISSION_VALUE_TYPE.FILTER) {
      return {
        categories: value.filters.map((filterItem: TExactSearchField) => {
          return {
            id: Number.isNaN(Number(filterItem.fieldId))
              ? filterItem.fieldId
              : TEObjectsService.fieldsById[Number(filterItem.fieldId)],
            values: filterItem.values,
          };
        }),
        searchFields: null,
        searchString: null,
      };
    }
    return value.value;
  }
  return Array.isArray(value) ? value : castArray(value.toString());
};

export type TActivityFilterNestedObject = Record<string, any>;

export const toNestedObject = (filters: Record<string, string>) => {
  const results: TActivityFilterNestedObject = {};
  Object.keys(filters).forEach((filterKey) => {
    const { category, field, key } = keyToObject(filterKey);
    set(results, compact([category, field, key]), filters[filterKey]);
  });
  return results;
};

export const addValuesToDeepestLevel = (filter: TActivityFilterNestedObject) => {
  Object.keys(filter).forEach((key: string) => {
    if (isEmptyValue(filter[key])) {
      // eslint-disable-next-line no-param-reassign
      delete filter[key];
      return;
    }
    if (typeof filter[key] === 'object' && !Array.isArray(filter[key])) {
      addValuesToDeepestLevel(filter[key]);
    } else {
      // eslint-disable-next-line no-param-reassign
      filter[key] = {
        values: filter[key],
        operator: toFilterOperator(filter[key]),
      };
    }
  });
};

export const convertToFetchActivitiesFilters = (filter: ActivityState['filter']) => {
  const nestedObject = toNestedObject(filter as TActivityFilterNestedObject);
  addValuesToDeepestLevel(nestedObject);
  return nestedObject;
};

const checkEditableStatus = (statuses: string[]) => {
  return [EActivityStatus.CREATED, EActivityStatus.SUBMITTED].some((stt) => statuses.includes(stt));
};

export const convertToActivityOverview = (
  act: TActivityResultsInResponseGroupByActivitySeries | TActivity2Fe,
  allOtherValues?: string[],
) => {
  const activitySeries = act as TActivityResultsInResponseGroupByActivitySeries & { trackId?: number };
  if ('activityIds' in activitySeries) {
    const indexedValue: Record<string, ActivityElementValue> = activitySeries.allValues.reduce(
      (results: Record<string, ActivityElementValue>, { activityValue }) => {
        if (!activityValue) return results;
        return {
          ...results,
          [activityValue.extId]: activityValue.value,
        };
      },
      {},
    );
    const weeks = activitySeries.startEndDates.flatMap((date: TActivityStartEndDate) => {
      return toActivityWeekRange(date.startDate, date.endDate);
    });
    return {
      ...act,
      numberOfTracks: activitySeries.trackId || activitySeries.numberOfTracks,
      id: activitySeries.activitySeriesId,
      weeks: toWeeksDisplay(weeks),
      duration: activitySeries.durations.map((duration: number) => toDurationDisplay(duration)).join(', '),
      ...allOtherValues?.reduce((results, extId) => {
        return {
          ...results,
          [extId]: indexedValue[extId],
        };
      }, {}),
      activityId: undefined,
      isEditable: checkEditableStatus(activitySeries.activityStatuses),
    };
  }

  // = group by "FLAT"
  const activity = act as TActivity2Fe;
  const indexedValue: Record<string, ActivityElementValue> = keyBy(activity.values, 'extId');
  return {
    ...activity,
    activitySeriesId: activity.activitySeriesGroup?.activitySeriesId ?? '', // ? TODO: Check if this is correct
    id: activity._id, // ? TODO: Check if this is correct
    activityId: activity._id,
    formInstanceId: activity.formInstanceId,
    activityType: activity.metadata.activityType,
    numberOfTracks: activity.metadata.track,
    primaryObject: activity.metadata.primaryObject,
    activityStatuses: [activity.activityStatus],
    sourceId: activity.sourceId,
    ...allOtherValues?.reduce((results, extId) => {
      return {
        ...results,
        [extId]: indexedValue[extId]?.value,
      };
    }, {}),
    weeks: toWeeksDisplay(toActivityWeekRange(act.metadata.startDate, act.metadata.endDate)),
    duration: toDurationDisplay(act.metadata.length),
    isEditable: checkEditableStatus([activity.activityStatus]),
  };
};

const trackDivider = '__TRACK-';
export const convertToTrackId = (seriesId: string, trackId: string | number) => {
  return `${seriesId}${trackDivider}${trackId}`;
};

export const getAllObjectsAndFieldsInActivityValues = (values: TActivityValue[]) => {
  const objectTypes: string[] = [];
  const fields: string[] = [];
  const objects: string[] = [];

  values.forEach((activityValue) => {
    // fields
    if (activityValue?.type === 'field') {
      fields.push(activityValue?.extId);
    }
    // object-filters
    else if (activityValue?.submissionValueType === 'FILTER') {
      objectTypes.push(activityValue?.extId);
    }
    // te-objects
    else if (activityValue?.type === 'object') {
      objectTypes.push(activityValue?.extId);
      objects.push(...(activityValue?.value ?? []));
    }
  });
  return {
    objectTypes,
    objects,
    fields,
  };
};
export default new TEActivitiesManager();
