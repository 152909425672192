import React, { useEffect, useMemo, useState } from 'react';
import ActivityDrawerWrapper from './ActivityDrawerWrapper';
import { useActivityDrawerManagement, useActivityTrackGetter } from './ActivityDrawer.hook';
import { ActivityInfo } from './ActivityDrawerComponents';
import ActivityValues from './ActivityValues';
import intl from 'i18n/intl';
import { ActivityEvents } from 'activities/pages/types/activity.type';
import {
  deleteActivitiesById,
  duplicateTrack,
  updateActivitiesValues,
  updateActivitySeriesTrackWeeks,
} from 'activities/services/activities.service';
import { EActivityStatus } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityStatus.enum';
import ActivityTags from './ActivityTags';
import ActivityWeeks from './ActivityWeeks';
import TEObjectsService from 'activities/services/TEObjects.service';
import { Button, Flex, Popconfirm, Typography } from 'antd';
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { organizationIdSelector } from 'slices/auth.slice';
import { EActivityGroupings } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityGroupings.enum';
import { TWeekSelectorValue } from '../WeekSelector/WeekSelector';
import { ESocketUseCase } from 'activities/pages/slices/activity.slice';
import { SeverityWarningIcon } from 'components/Icons';

const language = intl.messages as Record<string, string>;

function ActivityTrackDrawer() {
  const { open, onClose, onOpen } = useActivityDrawerManagement();
  const [saving, setSaving] = useState(false);
  const organizationId = useSelector(organizationIdSelector);
  const [activitySeriesId, setActivitySeriesId] = useState<undefined | string>(undefined);
  const [trackId, setTrackId] = useState<undefined | number>(undefined);
  const {
    activitySeries,
    loading,
    activityValues,
    allActivityValues,
    multipleValuesExtIds,
    initialValues,
    allDateRanges,
    triggerToRefetchSeries,
  } = useActivityTrackGetter({
    activitySeriesId,
    trackId,
  });

  const editable = useMemo(() => {
    if (!activitySeries) return false;
    return activitySeries.activityStatuses.every(
      (status) => ![EActivityStatus.IN_REVIEW].includes(status as EActivityStatus),
    );
  }, [activitySeries]);

  const onSave = async (updatedValues: Record<string, string>) => {
    if (!activitySeries || !activitySeries.formId) return;
    setSaving(true);
    await updateActivitiesValues({
      formId: activitySeries.formId.toString(),
      ids: activitySeries.activityIds,
      groupedBy: EActivityGroupings.FLAT,
      values: updatedValues,
    });
    setSaving(false);
    onClose();
  };

  const onOpenCallback = (e: Event) => {
    onOpen(() => {
      const { detail } = e as CustomEvent<{ activitySeriesId: string; trackId: number }>;
      setActivitySeriesId(detail.activitySeriesId);
      setTrackId(detail.trackId);
    });
  };

  useEffect(() => {
    document.addEventListener(ActivityEvents.OPEN_ACTIVITY_TRACK_DRAWER, onOpenCallback);
    return () => {
      document.removeEventListener(ActivityEvents.OPEN_ACTIVITY_TRACK_DRAWER, onOpenCallback);
    };
  }, []);

  useEffect(() => {
    if (!open) {
      setActivitySeriesId(undefined);
      setTrackId(undefined);
      document.dispatchEvent(new CustomEvent(ActivityEvents.CLOSE_ALL_ACTIVITY_DRAWER));
    }
  }, [open]);

  const onDeleteConfirm = async () => {
    if (organizationId && activitySeries?.activityIds) {
      await deleteActivitiesById(organizationId, activitySeries?.activityIds);
      onClose();
    }
  };

  const onDuplicateTrack = async () => {
    if (!organizationId || !activitySeries || !trackId) return; // Just to satisfy typing. If there is no activitySeries, then "editable" would be false anyway, so we would not get here
    await duplicateTrack({ activitySeries, organizationId, trackId });
  };

  const onRequestToChangeWeeks = (weeks: TWeekSelectorValue[], callback?: (_weeks: TWeekSelectorValue[]) => void) => {
    return new Promise((resolve, reject) => {
      try {
        if (organizationId && activitySeriesId) {
          updateActivitySeriesTrackWeeks(organizationId, activitySeriesId, trackId!, weeks).then(() => {
            triggerToRefetchSeries();
            if (typeof callback === 'function') callback(weeks);
            resolve(undefined);
          });
        } else {
          resolve(undefined);
        }
      } catch (err) {
        reject(err);
      }
    });
  };

  const modalTitle = useMemo(() => {
    return [
      activitySeries?.primaryObject ? TEObjectsService.getObjectLabel(activitySeries?.primaryObject) : 'N/A',
      `${activitySeries?.activityType ? TEObjectsService.getObjectLabel(activitySeries?.activityType) : 'N/A'} #${trackId}`,
    ].join(' - ');
  }, [trackId, activitySeries]);

  return (
    <ActivityDrawerWrapper
      saving={saving}
      open={open}
      onClose={onClose}
      title={modalTitle}
      subtitle={language.track}
      initialValues={initialValues}
      primaryObject={activitySeries?.primaryObject}
      editable={editable}
      onSave={onSave}
      loading={loading}
      refreshCurrentViewBoxAndLogicProps={{
        useCase: { socketUseCase: ESocketUseCase.activityTrackDrawer, activitySeriesId, trackId },
      }}
    >
      <>
        <ActivityInfo editable={false} loading={loading} activityType={activitySeries?.activityType} />
        <ActivityValues
          editable={editable}
          loading={loading}
          values={activityValues}
          allValues={allActivityValues}
          title={`Track #${trackId} info`}
          multipleValuesExtIds={multipleValuesExtIds}
        >
          <div className="te-mb-2">
            <Flex gap="small">
              <Button size="small" disabled={!editable} onClick={onDuplicateTrack}>
                <CopyOutlined /> {language.duplicate_track}
              </Button>
              <Popconfirm
                title={language['activities.confirm_to_delete_track']}
                trigger={['click']}
                onConfirm={onDeleteConfirm}
                icon={<SeverityWarningIcon alt={language['activities.confirm_to_delete_track']} />}
              >
                <Button size="small" disabled={!editable}>
                  <DeleteOutlined /> {language.delete_track}
                </Button>
              </Popconfirm>
            </Flex>
            <p className="te-mt-2">
              <Typography.Text>{language['activities.warning_when_editing_activities_in_track']}</Typography.Text>
            </p>
          </div>
          <ActivityWeeks
            editable={editable}
            allowMultiple
            alwaysFulFilled
            numberOfTracks={activitySeries?.numberOfTracks}
            disabledWeeks={[]}
            onSubmit={onRequestToChangeWeeks}
          />
        </ActivityValues>
        <ActivityTags formId={activitySeries?.formId?.toString()} editable={editable} />
      </>
    </ActivityDrawerWrapper>
  );
}

export default ActivityTrackDrawer;
