import React, { ReactNode, useEffect } from 'react';
import {
  Button,
  Drawer,
  Space,
  ConfigProvider,
  DrawerProps,
  Form,
  FormInstance,
  Modal,
  Typography,
  Alert,
  Flex,
} from 'antd';
import './ActivityDrawerWrapper.scss';
import intl from 'i18n/intl';
import { PrimaryObjectInfo } from './ActivityDrawerComponents';
import { ActivityEvents, TActivityRawValue } from 'activities/pages/types/activity.type';
import { pick } from 'lodash';
import RefreshCurrentViewBoxAndLogic, {
  TRefreshCurrentViewBoxAndLogicProps,
} from '../Socket/RefreshCurrentViewBoxAndLogic';
import { ESocketUseCase, setCurrentPageContainsDataToBeRefreshed } from 'activities/pages/slices/activity.slice';
import { useDispatch } from 'react-redux';
import { SeverityInfoIcon, SeverityWarningIcon } from 'components/Icons';

const language = intl.messages as Record<string, string>;

type TDrawerOptions = DrawerProps & {
  title: string;
  subtitle?: string;
  children?: ReactNode;
  initialValues?: Record<string, TActivityRawValue>;
  primaryObject?: string;
  editable?: boolean;
  loading?: boolean;
  saving?: boolean;
  onSave?: (values: Record<string, string>) => void;
  onClose?: () => void;
  afterOpenChange?: () => void;
  refreshCurrentViewBoxAndLogicProps: TRefreshCurrentViewBoxAndLogicProps;
};

function DrawerTitle({ title, subtitle }: { title: string; subtitle?: string }) {
  return (
    <div className="te-flex te-flex-col">
      <Typography.Title level={4}>{title}</Typography.Title>
      {subtitle && <Typography.Text style={{ fontWeight: 'normal' }}>{subtitle}</Typography.Text>}
    </div>
  );
}

type TDrawerActions = {
  onDiscard?: () => void;
  onSave?: () => void;
  form: FormInstance;
  saving?: boolean;
};
function DrawerActions({ onDiscard, onSave, saving, form }: TDrawerActions) {
  return (
    <Form form={form}>
      <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
        {() => {
          const allKeys = Object.keys(form.getFieldsValue());
          const touched = form.isFieldsTouched(allKeys.filter((key) => key !== 'weeks'));
          return (
            <Space>
              <Button disabled={!touched} onClick={onDiscard} size="small">
                {language.discard_changes}
              </Button>
              <Button disabled={!touched} onClick={onSave} size="small" type="primary" loading={saving}>
                {language.save}
              </Button>
            </Space>
          );
        }}
      </Form.Item>
    </Form>
  );
}

function ActivityDrawerWrapper(props: TDrawerOptions) {
  const {
    children,
    subtitle,
    title,
    onClose,
    open,
    initialValues,
    primaryObject,
    editable,
    loading,
    saving,
    onSave,
    afterOpenChange,
    refreshCurrentViewBoxAndLogicProps,
  } = props;
  const [form] = Form.useForm();
  const [modal, modalContextHolder] = Modal.useModal();
  const dispatch = useDispatch();

  useEffect(() => {
    form.setFieldsValue(initialValues);
    form.resetFields();
  }, [initialValues]);

  useEffect(() => {
    if (!open) {
      form.resetFields();
    }
  }, [open]);

  const onSaveValues = async () => {
    if (typeof onSave !== 'function') return;
    try {
      const values = await form.validateFields();
      const fields = Object.keys(values);
      const touchedFields = fields.filter((field) => form.isFieldTouched(field));
      if (touchedFields.length) {
        onSave(pick(values, touchedFields));
      }
    } catch (err) {
      console.log('Error when saving activity', err);
    }
  };

  const checkAndClose = () => {
    const allKeys = Object.keys(form.getFieldsValue());
    const touched = form.isFieldsTouched(allKeys.filter((key) => key !== 'weeks'));
    if (touched) {
      modal.confirm({
        title: language.sure_to_discard_all_changes,
        onOk: onCloseDrawer,
        icon: <SeverityWarningIcon alt={language.sure_to_discard_all_changes} />,
      });
      return;
    }
    if (typeof onClose === 'function') {
      dispatch(
        setCurrentPageContainsDataToBeRefreshed({
          socketUseCase: refreshCurrentViewBoxAndLogicProps.useCase.socketUseCase,
          newValue: false,
        }),
      );
    }
    onCloseDrawer();
  };

  const onCloseDrawer = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener(ActivityEvents.CLOSE_ACTIVITY_OVERVIEW_DRAWER, onCloseDrawer);
    return () => {
      document.removeEventListener(ActivityEvents.CLOSE_ACTIVITY_OVERVIEW_DRAWER, onCloseDrawer);
    };
  }, []);

  return (
    <Drawer
      data-testid="ACTIVITY_DRAWER_WRAPPER"
      className="activity-details"
      open={open}
      onClose={checkAndClose}
      title={<DrawerTitle title={title} subtitle={subtitle} />}
      extra={
        editable && (
          <DrawerActions form={form} onDiscard={() => form.resetFields()} onSave={onSaveValues} saving={saving} />
        )
      }
      width={480}
      maskClosable={!editable && loading === false}
      getContainer={document.querySelector('.activity-overview-table-wrapper') || document.body}
      rootStyle={{
        position: 'absolute',
      }}
      afterOpenChange={afterOpenChange}
      destroyOnClose
      mask={false}
    >
      <ConfigProvider
        theme={{
          components: {
            Form: {
              itemMarginBottom: 4,
            },
          },
        }}
      >
        <Flex vertical gap="middle">
          {/* I disagree with this rule. Prop spreading is super usefulll in this case because this component should not care about the props at all and just pass them through */
          /* eslint-disable-next-line react/jsx-props-no-spreading */}
          <RefreshCurrentViewBoxAndLogic {...refreshCurrentViewBoxAndLogicProps} />
          {!editable && (
            <Alert
              type="info"
              showIcon
              icon={
                <span className="te-mr-2">
                  <SeverityInfoIcon size={14} />
                </span>
              }
              message={language['activities.overview.warning_not_editable_for_in_review_activities']}
            />
          )}
        </Flex>
        <Form
          layout="horizontal"
          labelCol={{ span: 8, offset: 0 }}
          labelAlign="left"
          labelWrap
          form={form}
          initialValues={initialValues}
        >
          <PrimaryObjectInfo primaryObject={primaryObject} />
          {children}
        </Form>
      </ConfigProvider>
      {modalContextHolder}
    </Drawer>
  );
}

export default ActivityDrawerWrapper;
