import React from 'react';
import axios, { AxiosResponse } from 'axios';
import { configService } from './config.service';
import { TOption, TAPIRequest, TAPIRequestShorthand } from '../types/api.type';
import { notification } from 'antd';
import { getAccessTokenRaw } from './token.service';
import { SeveritySuccessIcon } from 'components/Icons';

/**
 * @function prepareOption
 * @description wrapper function to axios option object
 * @param {String} method request HTTP method
 * @param {Any} data data to pass in request
 * @param {Bool} requiresAuth whehter bearer token is required
 * @param {Obj} headers additional headers to be sent
 * @param {Obj} params explicitely set params
 * @returns {String}
 */
const prepareOption = (
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE',
  data: any,
  requiresAuth: Boolean,
  headers: any,
  params: any,
): TOption => {
  const option: TOption = {
    method,
    headers: {
      ...headers,
    },
    body: {},
  };

  if (requiresAuth) {
    const token = getAccessTokenRaw();

    option.headers = {
      Authorization: `Bearer ${token}`,
      ...headers,
    };
  }

  if (data) {
    if (method === 'GET') {
      option.params = { ...data, ...(params || {}) };
    } else {
      if (data instanceof FormData || data instanceof URLSearchParams) {
        option.data = data;
      } else if (Array.isArray(data)) {
        option.data = [...data];
      } else {
        option.data = { ...data };
      }
      if (params) option.params = { ...params };
    }
  }
  return option;
};

/**
 * @function getAPIUrl
 * @description returns URL for TE Preferences API depending on environment
 * @param {String} endpoint route to be called
 * @returns {String}
 */
const getAPIUrl = (endpoint: string) => {
  if (endpoint.search('http://') > -1 || endpoint.search('https://') > -1) return endpoint;
  return `${configService().REACT_APP_BASE_URL}${endpoint}`;
};

const apiRequest = async ({
  method,
  endpoint,
  absoluteUrl = false,
  data,
  params = null,
  requiresAuth = true,
  headers,
  successMessage = undefined,
  successToastTitle = 'Success',
  successToastType = 'success',
  errorMessage = undefined,
  timeout = undefined,
  signal,
}: TAPIRequest) => {
  const fullUrl = !absoluteUrl ? getAPIUrl(endpoint) : endpoint;
  const option = prepareOption(method, data, requiresAuth, headers, params);
  try {
    // Axios v0.22 returns AxiosResponse<never> unless called with a method ex. axios.get
    const response = (await axios(fullUrl, { ...option, timeout, signal })) as AxiosResponse<any>;
    // Get either notification.success or notification.info function based on options.
    const successNotificationFn = notification[successToastType];

    /**
     * When to show success messages:
     * x) For all POST, PATCH, PUT, DELETE calls unless explicitely opted out of (i.e., successMessage = false)
     * x) For GET if explicitely asked for (i.e., successMessage !== undefined && !== false)
     */
    if (method === 'GET' && !!successMessage) {
      successNotificationFn({
        key: configService().NOTIFICATION_KEY,
        message: successToastTitle,
        description: successMessage,
        icon: <SeveritySuccessIcon alt={typeof successMessage === 'string' ? successMessage : undefined} />,
      });
    }
    if (method !== 'GET' && (successMessage === undefined || !!successMessage)) {
      const notificationMessage = typeof successMessage === 'string' ? successMessage : 'The operation was successful';
      successNotificationFn({
        key: configService().NOTIFICATION_KEY,
        message: successToastTitle,
        description: notificationMessage,
        icon: <SeveritySuccessIcon alt={notificationMessage} />,
      });
    }
    return response?.data;
  } catch (error: any) {
    /**
     * When to show error messages:
     * x) For all failed calls unless explicitely opted out of (i.e., errorMessage = false)
     * x) If errorMessage is specified, show the custom message - otherwise show error.message.toString()
     */
    const isUnauthorizedGet = error?.response?.data?.status === 401 && method === 'GET';
    const showErrorMsg = errorMessage !== false && !isUnauthorizedGet;

    if (showErrorMsg) {
      notification.error({
        key: configService().NOTIFICATION_KEY,
        message: 'An error happened',
        description: typeof errorMessage === 'string' ? errorMessage : error.response?.data?.message,
      });
    }
    if (error?.response?.data?.message) {
      throw new Error(`URL: ${method} ${fullUrl}\n Error: ${error}\n Message: ${error.response.data.message}`);
    }
    // Forward the axios error
    throw error;
  }
};

const getAndDeleteDefaults = (method: 'GET' | 'DELETE', props: TAPIRequestShorthand) => ({
  method,
  endpoint: props.endpoint,
  absoluteUrl: props.absoluteUrl || false,
  data: props.data || undefined,
  requiresAuth: props.requiresAuth || true,
  headers: props.headers || undefined,
  successMessage: props.successMessage,
  errorMessage: props.errorMessage,
  signal: props.signal,
});

const postPutPatchDefaults = (method: 'PUT' | 'PATCH' | 'POST', props: TAPIRequestShorthand) => ({
  method,
  endpoint: props.endpoint,
  absoluteUrl: props.absoluteUrl || false,
  data: props.data || undefined,
  params: props.params || undefined,
  requiresAuth: props.requiresAuth || true,
  headers: props.headers || undefined,
  successMessage: props.successMessage,
  successToastTitle: props.successToastTitle || undefined,
  successToastType: props.successToastType || undefined,
  errorMessage: props.errorMessage,
  timeout: props.timeout,
  signal: props.signal,
});

const get = (props: TAPIRequestShorthand) => apiRequest(getAndDeleteDefaults('GET', props));
const post = (props: TAPIRequestShorthand) => apiRequest(postPutPatchDefaults('POST', props));
const put = (props: TAPIRequestShorthand) => apiRequest(postPutPatchDefaults('PUT', props));
const patch = (props: TAPIRequestShorthand) => apiRequest(postPutPatchDefaults('PATCH', props));
const delete_ = (props: TAPIRequestShorthand) => apiRequest(getAndDeleteDefaults('DELETE', props));

const listObjects = (
  payload: Record<
    string,
    unknown
  > /* TODO: Split pathway-service/src/utils/te-server-rest-access.utils.ts and re-use here for better typing */,
) => get({ endpoint: `${configService().REACT_APP_BASE_URL}/objects`, data: payload, successMessage: false });

const findObjects = (
  payload: Record<
    string,
    unknown
  > /* TODO: Split pathway-service/src/utils/te-server-rest-access.utils.ts and re-use here for better typing */,
  options?: {
    successMessage?: boolean | string;
    errorMessage?: boolean | string;
  },
) => {
  return post({
    endpoint: `${configService().REACT_APP_BASE_URL}/objects/find`,
    data: payload,
    successMessage: options?.successMessage || false,
    errorMessage: options?.errorMessage || false,
  });
};

const findTypes = (payload: Record<string, unknown>) =>
  post({ endpoint: `${configService().REACT_APP_BASE_URL}/types/find`, data: payload, successMessage: false });

const findFields = (payload: Record<string, unknown>) =>
  post({ endpoint: `${configService().REACT_APP_BASE_URL}/fields/find`, data: payload, successMessage: false });

const getMapping = () => get({ endpoint: `${configService().REACT_APP_BASE_URL}/apps/TE_PREFERENCES/mapping` });

const moduleExports = {
  get,
  post,
  put,
  patch,
  delete: delete_,
  findObjects,
  findTypes,
  findFields,
  getMapping,
  listObjects,
};

export default moduleExports;
