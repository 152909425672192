import React, { useEffect, useState } from 'react';
import { TActivity2Fe } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/Activity2.type';
import { EActivityGroupings } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityGroupings.enum';
import ActivityValues from 'activities/components/Drawer/ActivityValues';
import { ActivityEvents, TActivityRawValue } from 'activities/pages/types/activity.type';
import { updateActivitiesValues } from 'activities/services/activities.service';
import { Alert, Button, ConfigProvider, Flex, Form, Modal, Space } from 'antd';
import { groupBy, pick, uniq, uniqBy } from 'lodash';
import { useSelector } from 'react-redux';
import intl, { getInlineString } from 'i18n/intl';
import { activityOverviewSelector } from 'activities/pages/slices/activity.slice';
import { toActivityInitialValues } from 'activities/components/Drawer/ActivityDrawer.hook';
import { SeverityAlertIcon, SeverityInfoIcon } from 'components/Icons';
import { SeverityIcon } from '@timeedit/ui-components';

const language = intl.messages as Record<string, string>;

export default function EditSelectedActivitiesModal() {
  const activityOverview = useSelector(activityOverviewSelector);
  const [open, setOpen] = useState(false);
  const [activities, setActivities] = useState<TActivity2Fe[]>([]);
  const [activityIds, setActivityIds] = useState<string[]>([]);
  const [hasDisabledActivities, setHasDisabledActivities] = useState(false);
  const [initialValues, setInitialValues] = useState<Record<string, TActivityRawValue>>({});
  const [multipleValuesExtIds, setMultipleValuesExtIds] = useState<string[]>([]);
  const [saving, setSaving] = useState(false);

  const [form] = Form.useForm();

  const doGetActivities = (activityIds: string[]) => {
    // Only get stored activities for now, can update to call api to get activities if needed
    const activities = activityIds.map((id) => activityOverview.rowData[id] as TActivity2Fe);
    setActivities(activities);
    const allGroupedValues = groupBy(
      uniqBy(
        activities.flatMap((activity) => activity.values),
        (value) => `${value.extId}_${JSON.stringify(value.value)}`,
      ),
      'extId',
    );

    const allDurations = uniq(activities.map((activity) => activity.metadata.length));
    const initialValues = toActivityInitialValues({
      duration: allDurations[0],
      values: Object.values(allGroupedValues).flatMap((value) => value),
    });
    setInitialValues(initialValues);
    setMultipleValuesExtIds([
      ...Object.keys(allGroupedValues).filter((key) => allGroupedValues[key].length > 1),
      ...(allDurations.length > 1 ? ['duration'] : []),
    ]);
  };

  const onOpen = (e: Event) => {
    const { detail } = e as CustomEvent<{
      activityIds: string[];
      hasDisabledActivities: boolean;
    }>;
    if (!detail) return;
    const { activityIds, hasDisabledActivities } = detail;
    setActivityIds(activityIds);
    setHasDisabledActivities(hasDisabledActivities);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setActivityIds([]);
    setHasDisabledActivities(false);
  };

  const onConfirm = async () => {
    try {
      setSaving(true);
      const groupedByForm = groupBy(activities, 'formId');
      const values = form.getFieldsValue();
      await Promise.all(
        Object.keys(groupedByForm).map((formId) => {
          const firstActivityInForm = groupedByForm[formId][0];
          const allValueKeys = firstActivityInForm.values.map((val) => val.extId);
          const touchedFields = [...allValueKeys, 'duration'].filter((field) => form.isFieldTouched(field));
          return updateActivitiesValues({
            formId,
            groupedBy: EActivityGroupings.FLAT,
            ids: groupedByForm[formId].map(({ _id }) => _id),
            values: pick(values, touchedFields),
          });
        }),
      );
      setSaving(false);
      onClose();
    } catch (err) {
      console.log('There are some invalid form values ', err);
    }
  };

  useEffect(() => {
    document.addEventListener(ActivityEvents.OPEN_BULK_EDIT_ACTIVITIES, onOpen);
    return () => document.removeEventListener(ActivityEvents.OPEN_BULK_EDIT_ACTIVITIES, onOpen);
  }, []);

  useEffect(() => {
    if (open) {
      form.resetFields();
    }
  }, [open, initialValues]);

  useEffect(() => {
    if (!activityIds?.length) return;
    doGetActivities(activityIds);
  }, [activityIds]);

  return (
    <Modal
      open={open}
      title={getInlineString('activities.bulk_edit_activities.title', activityIds?.length)}
      destroyOnClose
      onCancel={onClose}
      zIndex={100}
      modalRender={(modalContent) => (
        <ConfigProvider
          theme={{
            components: {
              Form: {
                itemMarginBottom: 4,
              },
            },
          }}
        >
          <Form labelCol={{ span: 8, offset: 0 }} labelAlign="left" form={form} initialValues={initialValues}>
            {modalContent}
          </Form>
        </ConfigProvider>
      )}
      onOk={onConfirm}
      footer={
        <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
          {() => {
            const allKeys = Object.keys(form.getFieldsValue());
            const touched = form.isFieldsTouched(allKeys);
            return (
              <Space>
                <Button onClick={onClose} size="small">
                  {language.cancel}
                </Button>
                <Button disabled={!touched} onClick={onConfirm} size="small" type="primary" loading={saving}>
                  {language.confirm}
                </Button>
              </Space>
            );
          }}
        </Form.Item>
      }
    >
      <Flex vertical gap="small">
        <ActivityValues
          editable
          values={uniqBy(
            activities.flatMap((activity) => activity.values),
            'extId',
          )}
          allValues={activities.flatMap((activity) => activity.values)}
          multipleValuesExtIds={multipleValuesExtIds.filter((item) => {
            return item;
          })}
        />
        {hasDisabledActivities && (
          <Alert
            showIcon
            type="info"
            message={language['activities.bulk_edit_activities.warning_for_in_review_activities']}
            icon={
              <span className="te-mr-2">
                <SeverityInfoIcon
                  size={14}
                  alt={language['activities.bulk_edit_activities.warning_for_in_review_activities']}
                />
              </span>
            }
          />
        )}
      </Flex>
    </Modal>
  );
}
