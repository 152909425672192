/* Contains the "Changes have been made. Click the button to update." box and the logic to update the current view.
 */

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  checkIfChangedActivitiesAreOnCurrentPage,
  currentPageContainsDataToBeRefreshedSelector,
  ESocketUseCase,
  fetchActivitiesSuccess,
  refreshedActivitiesOfCurrentPageSelector,
  removeRefreshedActivitiesOfCurrentPage,
  setCurrentPageContainsDataToBeRefreshed,
  refetchActivityById,
  refetchActivitySeriesById,
  TSocketPageChangedState,
  fetchActivitiesWithCurrentStateForGroupBy,
} from 'activities/pages/slices/activity.slice';
import intl, { getInlineString } from 'i18n/intl';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Spin } from 'antd';
import {
  ESocketEvent,
  TDefaultSocketPayload,
} from '@timeedit/activity-manager-shared-lib/lib/internal/types/WebSocket.type';
import { useAppFeatureFlags } from '../../hooks/useAppFeatureFlags';
import { Button } from '@timeedit/ui-components';
import { LoadingOutlined, SyncOutlined } from '@ant-design/icons';
import { AMSocketContext, IAMSocketContext, TAMSocketEventMap } from 'services/am-socket.service';
import { SeverityAlertIcon, SeverityWarningIcon } from 'components/Icons';

type TSocketUseCaseOverViewTable = {
  socketUseCase: ESocketUseCase.overviewTable;
};

type TSocketUseCaseSingleActivityDrawer = {
  socketUseCase: ESocketUseCase.singleActivityDrawer;
  activityId: string | undefined;
  activitySeriesId: string | undefined;
};

type TSocketUseCaseActivitySeriesDrawer = {
  socketUseCase: ESocketUseCase.activitySeriesDrawer;
  activitySeriesId: string | undefined;
};

type TSocketUseCaseActivityTrackDrawer = {
  socketUseCase: ESocketUseCase.activityTrackDrawer;
  activitySeriesId: string | undefined;
  trackId: number | undefined;
};

export type TRefreshCurrentViewBoxAndLogicProps = {
  useCase:
    | TSocketUseCaseOverViewTable
    | TSocketUseCaseSingleActivityDrawer
    | TSocketUseCaseActivitySeriesDrawer
    | TSocketUseCaseActivityTrackDrawer;
};

const language = intl.messages as Record<string, string>;

const OFFLINE_ALERT_DELAY_MS = 3000;

function OfflineAlert({ socketContext }: { socketContext: IAMSocketContext }) {
  const [isVisible, setIsVisible] = useState(false);
  const [isReconnecting, setIsReconnecting] = useState(false);
  const [currentTimeout, setCurrentTimeout] = useState<ReturnType<typeof setTimeout> | undefined>(undefined);

  useEffect(() => {
    // Add a delay to show the alert after a while if the socket is not connected to avoid temporary flashes of the alert
    // This is to avoid showing the alert when the socket is not connected for a short period of time, e.g. when the page is reloaded or the socket connection is closed due to GCP's response timeouts
    if (socketContext.socket && socketContext.isConnected) {
      clearTimeout(currentTimeout);
      setCurrentTimeout(undefined);
      setIsVisible(false);
    } else if (!currentTimeout) {
      const timeout = setTimeout(() => {
        setIsVisible(true);
      }, OFFLINE_ALERT_DELAY_MS);

      setCurrentTimeout(timeout);
    }
  }, [socketContext.socket, socketContext.isConnected, currentTimeout]);

  return isVisible ? (
    <div className="container--wider">
      <Alert
        type="error"
        showIcon
        icon={
          <span className="te-mr-2">
            <SeverityAlertIcon
              alt={getInlineString(
                'activities.overview.table.offline_alert_message',
                language['activities.overview.table.offline_alert_button'],
              )}
            />
          </span>
        }
        message={
          <div className="te-flex te-items-center te-justify-between te-gap-3">
            <div className="ant-col">
              {getInlineString(
                'activities.overview.table.offline_alert_message',
                language['activities.overview.table.offline_alert_button'],
              )}
            </div>
            <div className="ant-col">
              <Button
                onClick={() => {
                  setIsReconnecting(true);
                  socketContext.socket?.disconnect();
                  socketContext.socket?.connect();
                  setIsReconnecting(false);
                }}
                size="small"
                icon={isReconnecting ? <Spin indicator={<LoadingOutlined spin />} size="small" /> : <SyncOutlined />}
              >
                {language['activities.overview.table.offline_alert_button']}
              </Button>
            </div>
          </div>
        }
      />
    </div>
  ) : null;
}

export default function RefreshCurrentViewBoxAndLogic({ useCase }: TRefreshCurrentViewBoxAndLogicProps) {
  const dispatch = useDispatch();

  const socketContext = useContext(AMSocketContext);
  const { showDmStatusFilter, examFlowV3 } = useAppFeatureFlags();
  const refreshedActivitiesOfCurrentPage = useSelector(refreshedActivitiesOfCurrentPageSelector);
  const currentPageContainsDataToBeRefreshed = useSelector(currentPageContainsDataToBeRefreshedSelector);

  const refetchCurrentPage = useCallback(() => {
    if (useCase.socketUseCase === ESocketUseCase.overviewTable) {
      if (refreshedActivitiesOfCurrentPage) {
        dispatch(fetchActivitiesSuccess(refreshedActivitiesOfCurrentPage));
        dispatch(removeRefreshedActivitiesOfCurrentPage());
      } else {
        dispatch(fetchActivitiesWithCurrentStateForGroupBy({ showDmStatusFilter, examFlowV3 }));
      }
    }
    if (useCase.socketUseCase === ESocketUseCase.singleActivityDrawer) {
      dispatch(refetchActivityById([useCase.activityId!]));
    }
    if (
      useCase.socketUseCase === ESocketUseCase.activitySeriesDrawer ||
      useCase.socketUseCase === ESocketUseCase.activityTrackDrawer
    ) {
      dispatch(refetchActivitySeriesById([useCase.activitySeriesId!]));
    }

    dispatch(setCurrentPageContainsDataToBeRefreshed({ socketUseCase: useCase.socketUseCase, newValue: false }));
  }, [dispatch, examFlowV3, refreshedActivitiesOfCurrentPage, showDmStatusFilter, useCase]);

  const returnIdsInDrawer = useCallback(() => {
    return {
      activityId: 'activityId' in useCase ? useCase.activityId : undefined,
      activitySeriesId: 'activitySeriesId' in useCase ? useCase.activitySeriesId : undefined,
      trackId: 'trackId' in useCase ? useCase.trackId : undefined,
    };
  }, [useCase]);

  const socketName = useCase.socketUseCase;
  const socketEventMap: Partial<TAMSocketEventMap> = {
    [ESocketEvent.ACTIVITY_GENERATION_UPDATE]: {
      [socketName]: (payload: TDefaultSocketPayload) => {
        console.log('ACTIVITY_GENERATION_UPDATE', payload);
      },
    },
    [ESocketEvent.ACTIVITY_GENERATION_STATUS]: {
      [socketName]: (payload: TDefaultSocketPayload) => {
        console.log('ACTIVITY_GENERATION_STATUS', payload);
        const playloadWithMockUser = {
          ...payload,
          data: { ...payload.data, metadata: { ...payload.data.metadata, actingUserId: 'mockValueSeeCommentBelow' } },
        };
        /*
        Chaning payload to mockValueSeeCommentBelow is ugly. But the situation is: AM-BE and DM do not align user well regarding sockets.
        We abuse someo of the existing functionality and hence we need to play tricks:

        In ACTIVITY_BATCH_OPERATION_UPDATE and other cases no actingUserId means:
        It's a Mongo trigger - which we do not want to react to in DM

        For ACTIVITY_GENERATION_STATUS, however, there is not user and most likely cannot be any user
        because im comes from TE Pref where there is sometimes no user.

        We would need to refactor all those cases and make it cleaner. But I feel that gets easier once everything is there and maybe even tested.
        There's already a task for it: https://timeedit.atlassian.net/browse/CONST-1132
        */
        dispatch(
          checkIfChangedActivitiesAreOnCurrentPage(
            playloadWithMockUser,
            { showDmStatusFilter, examFlowV3 },
            useCase.socketUseCase,
            returnIdsInDrawer(),
            refetchCurrentPage,
          ),
        );
      },
    },
    [ESocketEvent.ACTIVITY_BATCH_OPERATION_UPDATE]: {
      [socketName]: (payload: TDefaultSocketPayload) => {
        console.log('ACTIVITY_BATCH_OPERATION_UPDATE', payload);
        dispatch(
          checkIfChangedActivitiesAreOnCurrentPage(
            payload,
            { showDmStatusFilter, examFlowV3 },
            useCase.socketUseCase,
            returnIdsInDrawer(),
            refetchCurrentPage,
          ),
        );
      },
    },
    [ESocketEvent.ACTIVITY_SERIES_TRACKS_OR_WEEKS_CHANGED]: {
      [socketName]: (payload: TDefaultSocketPayload) => {
        console.log('ACTIVITY_BATCH_OPERATION_UPDATE', payload);
        dispatch(
          checkIfChangedActivitiesAreOnCurrentPage(
            payload,
            { showDmStatusFilter, examFlowV3 },
            useCase.socketUseCase,
            returnIdsInDrawer(),
            refetchCurrentPage,
          ),
        );
      },
    },
    [ESocketEvent.ACTIVITY_SERIES_TRACKS_ADDED]: {
      [socketName]: (payload: TDefaultSocketPayload) => {
        console.log('ACTIVITY_BATCH_OPERATION_UPDATE', payload);
        dispatch(
          checkIfChangedActivitiesAreOnCurrentPage(
            payload,
            { showDmStatusFilter, examFlowV3 },
            useCase.socketUseCase,
            returnIdsInDrawer(),
            refetchCurrentPage,
          ),
        );
      },
    },

    [ESocketEvent.FILTER_LOOKUP_MAP_UPDATE]: {
      [socketName]: (payload: TDefaultSocketPayload) => {
        console.log('FILTER_LOOKUP_MAP_UPDATE', payload);
      },
    },
  };
  useEffect(() => {
    console.log('socketEventMap', socketEventMap, typeof socketContext.setEventMap);
    socketContext.setEventMap(socketEventMap);
    return () => {
      console.log('removeEventMap', socketEventMap);
      socketContext.removeEventMap(socketEventMap);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onConnect = () => {
      if (!socketContext.socket?.recovered) {
        // If the socket has not recovered, we want to refetch the current page as there might have been changes while the socket was disconnected
        refetchCurrentPage();
      }
    };

    if (socketContext.socket) {
      socketContext.socket.on('connect', () => onConnect());
    }

    return () => {
      if (socketContext.socket) {
        socketContext.socket.off('connect', onConnect);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this effect once when the socket has been initialized
  }, [!!socketContext.socket, refetchCurrentPage]);

  const returnAppropriateAlert = (pageChangedState: TSocketPageChangedState) => {
    if (pageChangedState === 'updated') {
      return (
        <Alert
          style={{ width: '100%' }}
          showIcon
          icon={<SeverityWarningIcon alt={language.changes_made_to_table} />}
          type="warning"
          key="currentPageContainsDataToBeRefreshedInfo"
          message={
            <div className="te-flex">
              <div className="ant-col">{language.changes_made_to_table}</div>
              <div className="ant-col" style={{ marginLeft: 'auto' }}>
                <Button onClick={refetchCurrentPage} size="small" icon={<SyncOutlined />}>
                  {language.update}
                </Button>
              </div>
            </div>
          }
        />
      );
    }

    if (pageChangedState === 'drawerItemDeleted') {
      return (
        <Alert
          style={{ width: '100%' }}
          showIcon
          icon={
            <span className="te-mr-2">
              <SeverityAlertIcon alt={language.activity_deleted} />
            </span>
          }
          type="error"
          key="currentPageContainsDataToBeRefreshedInfo"
          message={
            <div className="te-flex">
              <div className="ant-col">{language.activity_deleted}</div>
            </div>
          }
        />
      );
    }

    return null;
  };

  return (
    <>
      <OfflineAlert socketContext={socketContext} />
      {currentPageContainsDataToBeRefreshed[useCase.socketUseCase] && (
        <div className="ant-row te-flex container--wider" style={{ margin: '10px' }}>
          {returnAppropriateAlert(currentPageContainsDataToBeRefreshed[useCase.socketUseCase])}
        </div>
      )}
    </>
  );
}
