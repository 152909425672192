import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Column, EditableTable } from '@timeedit/ui-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  activitiesLoadingSelector,
  activitiesPaginationSelector,
  activityGroupBySelector,
  activityOverviewSelector,
  changeExpandedRows,
  changeSelectedActivityIds,
  changeTablePagination,
  fetchActivitiesInTrack,
  fetchTracksInSeries,
  selectedActivitiesSelector,
  activityExpandedRowsSelector,
  collapseAllRows,
} from 'activities/pages/slices/activity.slice';
import { ColumnTitle } from '@timeedit/ui-components/lib/src/components/EditableTable/ColumnTitle';
import intl, { getInlineString } from 'i18n/intl';
import Tag from '@timeedit/ui-components/lib/src/components/Tag/Tag';
import {
  ActivityEvents,
  TActivityResultsInResponseGroupByActivitySeries,
  TActivityRowRecord,
} from 'activities/pages/types/activity.type';
import TEObjectsService from 'activities/services/TEObjects.service';
import ExpandIcon from './ExpandIcon';
import ActivitiesTableFooter from './ActivitiesTableFooter';
import './ActivitiesTable.scss';
import { BranchesOutlined } from '@ant-design/icons';
import ActivityValueCell from './ActivityValueCell';
import { Button, ConfigProvider, Divider, Flex, Skeleton, Table, Typography, theme } from 'antd';
import { compact, keyBy, orderBy } from 'lodash';
import { TableRowSelection } from 'antd/es/table/interface';
import { EActivityStatus } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityStatus.enum';
import { EActivityGroupings } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityGroupings.enum';
import EditSelectedActivitiesModal from '../Modal/EditSelectedActivitiesModal/EditSelectedActivitiesModal';
import localStorageHelper from 'utils/localStorage.helper';
import { useColumnsManager } from './ActivityTable.hooks';

const language = intl.messages as Record<string, string>;

function MemorizedTable({
  visibleColumns,
  children,
  columns: propsColumns,
}: {
  visibleColumns: string[];
  columns: Column<TActivityRowRecord>[];
  children: ({ columns }: { columns: Column<TActivityRowRecord>[] }) => ReactElement;
}) {
  const [columns, setColumns] = useState<Column<TActivityRowRecord>[]>([]);
  useEffect(() => {
    setColumns([]);
    // Add timeout to make sure rerender all columns
    setTimeout(() => {
      setColumns(
        orderBy(
          propsColumns?.filter((col) => visibleColumns.includes(col.key)),
          (col) => visibleColumns.findIndex((key) => key === col.key),
        ),
      );
    }, 0);
  }, [visibleColumns.join(',')]);

  if (!columns.length) return <Skeleton />;
  return children({ columns });
}

export default function ActivitiesTable({ type }: { type: 'ACTIVITY_SERIES' | 'FLAT' }) {
  const dispatch = useDispatch();
  const overview = useSelector(activityOverviewSelector);
  const selectedRowKeys = useSelector(selectedActivitiesSelector);
  const loading = useSelector(activitiesLoadingSelector);
  const pagination = useSelector(activitiesPaginationSelector);
  const activityGroupBy = useSelector(activityGroupBySelector);
  const expandedRows = useSelector(activityExpandedRowsSelector);
  const tableId = useMemo(() => `activitiesTable_${type}`, [type]);
  const isNested = useMemo(() => type === 'ACTIVITY_SERIES', [type]);
  const storedWidths = localStorageHelper.columnsWidth[activityGroupBy];
  const { token } = theme.useToken();

  const [highlightedRowId, setHighlightedRowId] = useState<null | string>(null);

  const onOpenDrawer = ({
    rowId,
    activitySeriesId,
    activityId,
    trackId,
  }: {
    rowId: string;
    activitySeriesId?: string;
    activityId?: string;
    trackId?: number;
  }) => {
    if (!rowId || (!activitySeriesId && !activityId) || rowId === highlightedRowId) return;
    document.dispatchEvent(new CustomEvent(ActivityEvents.CLOSE_ACTIVITY_OVERVIEW_DRAWER));
    // Timeout to make sure current drawer is closed before opening new one
    setTimeout(() => {
      setHighlightedRowId(rowId);
      if (activitySeriesId) {
        if (trackId) {
          document.dispatchEvent(
            new CustomEvent(ActivityEvents.OPEN_ACTIVITY_TRACK_DRAWER, {
              detail: {
                activitySeriesId,
                trackId,
              },
            }),
          );
        } else {
          document.dispatchEvent(
            new CustomEvent(ActivityEvents.OPEN_ACTIVITY_SERIES_DRAWER, {
              detail: {
                activitySeriesId,
              },
            }),
          );
        }
      } else if (activityId) {
        document.dispatchEvent(
          new CustomEvent(ActivityEvents.OPEN_ACTIVITY_DRAWER, {
            detail: {
              activityId,
            },
          }),
        );
      }
    }, 0);
  };
  const onCloseDrawer = () => {
    setHighlightedRowId(null);
  };

  const columnRender = useMemo((): Record<string, (rowRecord: TActivityRowRecord) => null | string | ReactElement> => {
    return {
      activityType: (rowRecord: TActivityRowRecord) => {
        if (!isNested) {
          return <ActivityValueCell activityValue={overview.rowData[rowRecord.id]?.activityType} />;
        }
        if (rowRecord.level === 'series')
          return (
            <ActivityValueCell activityValue={overview.rowData[rowRecord.id]?.activityType}>
              &nbsp;x&nbsp;{overview.rowData[rowRecord.id]?.numberOfTracks}
            </ActivityValueCell>
          );

        if (rowRecord.level === 'track') {
          return (
            <div>
              <BranchesOutlined style={{ transform: 'rotate(90deg)', fontSize: 16 }} />
              &nbsp;
              <span>{rowRecord.trackId}</span>
            </div>
          );
        }
        return null;
      },
      weeks: (rowRecord: TActivityRowRecord) => {
        if (!overview.rowData[rowRecord.id]?.weeks) {
          return 'N/A';
        }
        const weekDisplay = `w ${overview.rowData[rowRecord.id]?.weeks}`;
        if (!rowRecord.level || rowRecord.level === 'activity') return <span>{weekDisplay}</span>;
        return <Typography.Text>{`w ${overview.rowData[rowRecord.id]?.weeks}`}</Typography.Text>;
      },
      duration: (rowRecord: TActivityRowRecord) => {
        return overview.rowData[rowRecord.id]?.duration;
      },
      status: (rowRecord: TActivityRowRecord) =>
        overview.rowData[rowRecord.id]?.activityStatuses.map((status: string, statusIdx: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <Tag key={`status_${statusIdx}`}>{status.replace(/_/g, ' ')}</Tag>
        )),
      ...(overview.allOtherValues || []).reduce((results, col: string) => {
        return {
          ...results,
          [`activity_${col}`]: (rowRecord: TActivityRowRecord) => {
            const rowData = overview.rowData[rowRecord.id];
            const allValues = rowData?.allValues?.map(
              (value: TActivityResultsInResponseGroupByActivitySeries['allValues'][0]) => value.activityValue,
            );
            return <ActivityValueCell activityValue={rowData?.[col]} extId={col} allValues={allValues} />;
          },
        };
      }, {}),
    };
  }, [overview, highlightedRowId]);

  const columns = useMemo((): Column<TActivityRowRecord>[] => {
    return compact([
      {
        ...(Table.EXPAND_COLUMN as Column<TActivityRowRecord>),
        key: 'expand',
        render: () => null,
        width: 32,
      },
      {
        title: <ColumnTitle title={language['activities.overview.table.activity_type'] as string} />,
        key: 'activityType',
        width: 180,
        resizable: true,
      },
      {
        title: <ColumnTitle title={language['activities.overview.table.weeks'] as string} />,
        key: 'weeks',
        width: 180,
        resizable: true,
      },
      {
        title: <ColumnTitle title={language['activities.overview.table.duration'] as string} />,
        key: 'duration',
        width: 120,
      },
      {
        title: <ColumnTitle title={language.status as string} />,
        key: 'status',
        width: 120,
      },
      ...(overview.allOtherValues || []).map((col: string) => ({
        title: <ColumnTitle title={TEObjectsService.getObjectTypeLabel(col, TEObjectsService.getFieldLabel(col))} />,
        key: `activity_${col}`,
        width: 250,
        resizable: true,
      })),
    ]);
  }, [overview.rowData]);

  const { modalTrigger, modal, visibleColumns } = useColumnsManager({
    tableId: activityGroupBy,
    columns: columns.map((col) => ({ title: col.title, key: col.key })),
  });

  const onPaginationChange = useCallback(
    (page?: number, perPage?: number) => {
      dispatch(
        changeTablePagination({
          page,
          perPage,
        }),
      );
    },
    [dispatch],
  );

  const handleRowSelection: TableRowSelection<unknown>['onChange'] = (selectedKeys) => {
    dispatch(
      changeSelectedActivityIds([
        ...(selectedRowKeys || []).filter((key) => !allRowIds.includes(key)),
        ...(selectedKeys as string[]),
      ]),
    );
  };
  const onCollapseAll = () => {
    dispatch(collapseAllRows());
  };

  const dataSources = useMemo(() => {
    return isNested ? overview.series : overview.activities;
  }, [type, overview]);

  const hasExpandedRows = useMemo(() => {
    const indexedDatasources = keyBy(dataSources, 'id');
    return expandedRows.some((item) => {
      return !!indexedDatasources[item]?.children;
    });
  }, [expandedRows]);

  const activityActionsDisabled = useMemo(() => {
    return dataSources?.some(
      (item) =>
        selectedRowKeys?.includes(item.id) &&
        // always use activity status in new exam flow
        overview.rowData[item.id]?.activityStatuses?.includes(EActivityStatus.IN_REVIEW),
    );
  }, [selectedRowKeys, dataSources]);

  const allRowIds = useMemo(() => {
    const getChildrenKey = (items?: TActivityRowRecord[]): string[] => {
      if (!items) return [];
      return items.flatMap((item) => {
        return [item.id, ...getChildrenKey(item.children)];
      });
    };
    return getChildrenKey(dataSources);
  }, [dataSources]);

  const selectedRowIds = useMemo(() => {
    return selectedRowKeys?.filter((id) => allRowIds.includes(id));
  }, [selectedRowKeys, dataSources]);

  useEffect(() => {
    document.addEventListener(ActivityEvents.CLOSE_ALL_ACTIVITY_DRAWER, onCloseDrawer);
    return () => {
      document.removeEventListener(ActivityEvents.CLOSE_ALL_ACTIVITY_DRAWER, onCloseDrawer);
    };
  }, []);
  return (
    <div
      className={`activity-series-table te-flex te-flex-col te-flex-1 ${isNested ? 'activity-series-table--nested-table' : ''}`}
      data-testid="ACTIVITIES_TABLE"
    >
      <div className="header">
        <Flex align="center">
          <Typography.Text>
            {getInlineString(
              activityGroupBy === EActivityGroupings.ACTIVITY_SERIES
                ? 'activities.overview.table.showing_number_of_activities'
                : 'activities.overview.table.showing_number_of_activities_flat',
              dataSources?.length ?? 0,
              pagination.totalActivities,
            )}
          </Typography.Text>
          {activityGroupBy === EActivityGroupings.ACTIVITY_SERIES && (
            <Flex align="center">
              <Divider type="vertical" />
              <Button size="small" onClick={onCollapseAll} disabled={!hasExpandedRows}>
                {language.collapse_all}
              </Button>
            </Flex>
          )}
        </Flex>
        <Flex align="center">
          <Divider type="vertical" />
          {modalTrigger}
        </Flex>
      </div>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              rowExpandedBg: token.colorBgBase,
              rowHoverBg: token.colorFillSecondary,
            },
          },
        }}
      >
        <MemorizedTable columns={columns} visibleColumns={visibleColumns}>
          {({ columns: updatedColumns }) => {
            const allColumns = columns.map((col) => col.key);
            return (
              <EditableTable
                id={tableId}
                loading={loading}
                columns={updatedColumns
                  .filter(({ key }) => allColumns.includes(key))
                  .map((col) => ({
                    ...col,
                    render: columnRender[col.key] || col.render,
                    width: storedWidths?.[col.key] || col.width,
                  }))}
                dataSource={dataSources}
                rowKey="id"
                tableLayout="fixed"
                expandable={{
                  columnWidth: 32,
                  showExpandColumn: true,
                  expandIcon: ({ expanded, onExpand, record }) => {
                    return <ExpandIcon expanded={expanded} onExpand={onExpand} record={record} />;
                  },
                  expandedRowKeys: expandedRows,
                  onExpand: (expanded: boolean, record: TActivityRowRecord) => {
                    dispatch(changeExpandedRows({ rowIds: [record.id], expanded }));
                    if (expanded) {
                      if (record.level === 'series') {
                        dispatch(fetchTracksInSeries([record.id]));
                      } else if (record.level === 'track' && record.activitySeriesId && record.trackId) {
                        dispatch(
                          fetchActivitiesInTrack([{ seriesId: record.activitySeriesId, trackId: record.trackId }]),
                        );
                      }
                    }
                  },
                }}
                rowSelection={{
                  type: 'checkbox',
                  selectedRowKeys: selectedRowIds,
                  checkStrictly: false,
                  onChange: handleRowSelection,
                  columnWidth: 32,
                  getCheckboxProps: (record) => {
                    if (!isNested) return {};
                    switch (record.level) {
                      case 'series':
                        return {};
                      case 'track':
                        return {
                          style: { transform: 'translateX(32px)' },
                        };
                      default:
                        return {
                          style: {
                            transform: 'translateX(64px)',
                          },
                        };
                    }
                  },
                }}
                onRow={(row) => ({
                  onClick: () => {
                    switch (row.level) {
                      case 'series': {
                        onOpenDrawer({
                          rowId: row.id,
                          activitySeriesId: row.id,
                        });
                        break;
                      }
                      case 'track': {
                        onOpenDrawer({
                          rowId: row.id,
                          trackId: row.trackId,
                          activitySeriesId: row.activitySeriesId,
                        });
                        break;
                      }
                      default: {
                        onOpenDrawer({
                          rowId: row.id,
                          activityId: row.id,
                        });
                      }
                    }
                  },
                  className: 'treetable-clickable',
                })}
                pagination={false}
                footer={() => (
                  <ActivitiesTableFooter
                    activityActionsDisabled={activityActionsDisabled}
                    onPaginationChange={onPaginationChange}
                  />
                )}
                onColumnSizeChange={(widths) => {
                  localStorageHelper.updateColumnsWidth(activityGroupBy, { ...storedWidths, ...widths });
                }}
                rowClassName={(record) => {
                  if (record.id === highlightedRowId) return 'ant-table-row-selected';
                  return '';
                }}
              />
            );
          }}
        </MemorizedTable>
      </ConfigProvider>
      <EditSelectedActivitiesModal />
      {modal}
    </div>
  );
}
