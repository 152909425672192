import React, { ReactNode } from 'react';

import { TActivityElementValue } from 'activities/pages/types/activity.type';
import { Typography } from 'antd';
import { toActivityValueDisplay } from './ActivitiesTable.utils';
import ActivityCategoriesValue from './ActivityCategoriesValue';
import isEmpty from 'lodash/isEmpty';
import { getMultipleValuesDisplayValue, hasMultipleValues } from 'activities/utils';
import { ElementValue } from '../Drawer/ElementValue';

function ActivityValueCell({
  activityValue,
  extId,
  allValues,
  children,
}: {
  activityValue: TActivityElementValue;
  extId?: string;
  allValues?: TActivityElementValue[];
  children?: ReactNode;
}) {
  if (hasMultipleValues(allValues, extId)) {
    const displayValue = getMultipleValuesDisplayValue(allValues, extId);
    return <ElementValue hasMultipleValues>{displayValue}</ElementValue>;
  }

  if (isEmpty(activityValue)) return null;

  if (activityValue.categories) {
    return <ActivityCategoriesValue activityValueCategories={activityValue.categories} />;
  }

  return (
    <Typography.Text ellipsis style={{ color: 'inherit' }}>
      {toActivityValueDisplay(activityValue)}
      {children || ''}
    </Typography.Text>
  );
}

export default React.memo(ActivityValueCell);
