import {
  activityFiltersSelector,
  changeFilterValue,
  activityFilterPeriodSelector,
  ActivityState,
} from 'activities/pages/slices/activity.slice';
import {
  getActivityLookupMap,
  getActivityLookupLeaf,
  isEmptyValue,
  objectToKey,
  keyToObject,
  getActivityMandatoryFields,
} from 'activities/services/activities.service';
import TEObjectsService from 'activities/services/TEObjects.service';
import TagsManager from 'activities/services/Tags.service';
import intl from 'i18n/intl';
import { castArray, compact, debounce, get, isEmpty, startCase } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { organizationIdSelector } from 'slices/auth.slice';
import { EFilterType, Filter, IOption, TFilterValue } from '@timeedit/ui-components';
import { EActivityStatus } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityStatus.enum';
import { IFilter, IFilterField, TSavedFilter } from '@timeedit/ui-components/lib/src/components/Filter/Filter.type';
import { v4 as uuid } from 'uuid';
import { Spin } from 'antd';
import './ActivityFilters.scss';
import { toDurationDisplay } from 'activities/utils';
import localStorageHelper from 'utils/localStorage.helper';

const SINGLE_VALUE_FIELDS = ['totalTracks'];
const EXCLUDED_FIELDS = [
  'submitter',
  'submitterName',
  'submissionId',
  'submissionIdLabels',
  'startTime',
  'endTime',
  'activitySeriesId',
  'timezone',
  'primaryObject',
  'activity_type',
];

const language = intl.messages;
type TFilterLookup = {
  [key: string]: string | Record<string, string>;
};

export default function ActivityFiltersWrapper() {
  const dispatch = useDispatch();
  const filters = useSelector(activityFiltersSelector);
  const filterPeriod = useSelector(activityFilterPeriodSelector);
  const organizationId = useSelector(organizationIdSelector);
  const [filtersLookup, setFiltersLookup] = useState<TFilterLookup>({});
  const [optionalFields, setOptionalFields] = useState<undefined | IFilterField[]>();
  const [mandatoryObjects, setMandatoryObjects] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const defaultFilterValues = useMemo(() => {
    return filters;
  }, []);

  const defaultFilterOptions = useMemo(() => {
    return localStorageHelper.filters.options;
  }, []);

  useEffect(() => {
    const doGettingLookup = async () => {
      if (!organizationId) return;
      setLoading(true);
      const response = await getActivityLookupMap(organizationId, { date: filterPeriod });
      const activityCreateTemplates = await getActivityMandatoryFields(organizationId, { date: filterPeriod });
      setMandatoryObjects(Object.values(activityCreateTemplates).flatMap((field) => field));
      setFiltersLookup(response);
      setLoading(false);
    };
    if (filterPeriod?.[0] && filterPeriod?.[1]) {
      doGettingLookup();
    }
  }, [filterPeriod]);

  useEffect(() => {
    const optionalFields: IFilterField[] = [];
    Object.keys(filtersLookup)
      .filter((fieldKey) => {
        return !EXCLUDED_FIELDS.includes(fieldKey) && !defaultFields.some(({ key }) => key === fieldKey);
      })
      .forEach((fieldKey) => {
        switch (fieldKey) {
          case 'objects': {
            Object.keys(filtersLookup.objects).forEach((objectType) => {
              const categoriedFields = TEObjectsService.categories[TEObjectsService.objectTypes[objectType]?.id];
              const key = objectToKey({ category: 'objects', key: objectType });
              if (!defaultFieldsKey.includes(key)) {
                optionalFields.push({
                  key,
                  label: TEObjectsService.getObjectTypeLabel(objectType),
                  type: EFilterType.text,
                  multiselect: true,
                  searchable: true,
                  category: isEmpty(categoriedFields)
                    ? (language.objects as string)
                    : TEObjectsService.getObjectTypeLabel(objectType),
                });
              }
              if (!isEmpty(categoriedFields)) {
                categoriedFields.forEach((fieldExtId) => {
                  const field = TEObjectsService.fields[fieldExtId];
                  const isCategories = field?.fieldType === 'CATEGORY';
                  const key = objectToKey({
                    category: 'category',
                    key: fieldExtId,
                    field: TEObjectsService.objectTypes[TEObjectsService.objectTypesById[field?.types?.[0]]]?.extId,
                  });
                  optionalFields.push({
                    key,
                    label: `${TEObjectsService.getObjectTypeLabel(objectType)} / ${TEObjectsService.getFieldLabel(fieldExtId)}`,
                    type: isCategories ? EFilterType.text : EFilterType.boolean,
                    multiselect: true,
                    searchable: true,
                    category: TEObjectsService.getObjectTypeLabel(objectType),
                  });
                });
              }
            });
            break;
          }
          case 'fields': {
            Object.keys(filtersLookup.fields).forEach((objectType) => {
              const key = objectToKey({ category: 'fields', key: objectType });
              optionalFields.push({
                key,
                label: TEObjectsService.getFieldLabel(objectType),
                type: EFilterType.text,
                multiselect: true,
                searchable: true,
                category: language.fields as string,
              });
            });
            break;
          }
          case 'objectFilters': {
            const objectFilterKeys = Object.keys(filtersLookup.objectFilters);
            objectFilterKeys.forEach((field) => {
              if (field) {
                const allLeafs = Object.keys((filtersLookup.objectFilters as any)[field] || {});
                allLeafs.forEach((leaf) => {
                  const key = objectToKey({ category: 'objectFilters', field, key: leaf });
                  optionalFields.push({
                    key,
                    label: `${TEObjectsService.getObjectTypeLabel(field)} / ${TEObjectsService.getFieldLabel(leaf)}`,
                    type: EFilterType.text,
                    multiselect: true,
                    searchable: true,
                    category: language.object_filters as string,
                  });
                });
              }
            });
            break;
          }

          default: {
            optionalFields.push({
              key: fieldKey,
              label: startCase(fieldKey),
              type: EFilterType.text,
              multiselect: !SINGLE_VALUE_FIELDS.includes(fieldKey),
              searchable: !SINGLE_VALUE_FIELDS.includes(fieldKey),
            });
          }
        }
      });

    setOptionalFields(optionalFields);
  }, [filtersLookup]);

  const defaultFields: IFilterField[] = useMemo(() => {
    return [
      ...mandatoryObjects.map((objectType) => ({
        key: objectToKey({ category: 'objects', key: objectType }),
        label: TEObjectsService.getObjectTypeLabel(objectType),
        multiselect: true,
        searchable: true,
        type: EFilterType.text,
      })),
      {
        key: objectToKey({ key: 'status' }),
        label: language.status as string,
        multiselect: true,
        searchable: true,
        type: EFilterType.text,
      },
      {
        key: objectToKey({ key: 'duration' }),
        label: language.duration as string,
        multiselect: true,
        searchable: true,
        type: EFilterType.text,
      },
      {
        key: objectToKey({ key: 'tag' }),
        label: language.tag as string,
        multiselect: true,
        searchable: true,
        type: EFilterType.text,
      },
      {
        key: objectToKey({ key: 'activitySeriesNoOfWeeks' }),
        label: language.number_of_weeks as string,
        multiselect: true,
        searchable: true,
        type: EFilterType.text,
      },
    ].filter((field) => {
      if (!field.key) return false;
      const { key, category } = keyToObject(field.key);
      if (key === 'status') return true;
      return get(filtersLookup, compact([category, key]));
    });
  }, [filtersLookup, mandatoryObjects]);

  const defaultFieldsKey: string[] = useMemo(() => {
    return defaultFields.map(({ key }) => key);
  }, [defaultFields]);

  const onFiltersChange = useCallback(
    debounce((updatedFilters: Record<string, TFilterValue>) => {
      const formattedValues = Object.keys(updatedFilters).reduce((results, key) => {
        const valueisEmpty = isEmptyValue(updatedFilters[key]);
        if (valueisEmpty) return results;
        return {
          ...results,
          [key]: SINGLE_VALUE_FIELDS.includes(key)
            ? updatedFilters[key]
            : castArray(updatedFilters[key] as string | string[]),
        };
      }, {});
      dispatch(changeFilterValue(formattedValues));
    }, 500),
    [],
  );

  return (
    <Spin spinning={loading} data-testid="ACTIVITY_FILTERS_WRAPPER">
      {!isEmpty(filtersLookup) && optionalFields && (
        <ActivityFilters
          defaultFields={defaultFields}
          optionalFields={optionalFields}
          onChange={onFiltersChange}
          defaultFilterValues={defaultFilterValues}
          defaultFilterOptions={defaultFilterOptions}
        />
      )}
    </Spin>
  );
}

type TActivityFilters = {
  defaultFields: IFilterField[];
  optionalFields: IFilterField[];
  onChange: (filters: Record<string, TFilterValue>) => void;
  defaultFilterValues: ActivityState['filter'];
  defaultFilterOptions: Record<string, IOption[]>;
};
function ActivityFilters(props: TActivityFilters) {
  const organizationId = useSelector(organizationIdSelector);
  const filterPeriod = useSelector(activityFilterPeriodSelector);
  const { defaultFields, optionalFields, onChange, defaultFilterValues, defaultFilterOptions } = props;

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<Record<string, IOption[]>>(defaultFilterOptions);
  const [readyFields, setReadyFields] = useState<string[]>(['status']);
  const [filterValues, setFilterValues] = useState<Record<string, TFilterValue>>({
    status: [],
    ...defaultFields.reduce(
      (results, item) => ({
        ...results,
        [item.key]: item.multiselect ? [] : undefined,
      }),
      {},
    ),
    ...(defaultFilterValues || {}),
  });
  const [savedFilters, setSavedFilters] = useState<TSavedFilter[]>(localStorageHelper.savedFilters);

  const doGettingLookupLeaf = async ({ fieldKey, type }: { fieldKey: string; type?: 'object' | 'field' }) => {
    const { category, field, key } = keyToObject(fieldKey);

    if (!organizationId || !key) return;
    if (!filterPeriod?.[0] || !filterPeriod?.[1]) return;
    if (readyFields.includes(key)) return;

    const isTag = key === 'tag';

    setLoading(true);
    let response;
    let allFilterValues: string[] = [];

    if (category) {
      if (category === 'category') {
        if (TEObjectsService.fields[key].fieldType === 'CATEGORY' && TEObjectsService.fields[key]?.categories) {
          allFilterValues = TEObjectsService.fields[key]?.categories as string[];
        }
      } else if (field) {
        response = await getActivityLookupLeaf(organizationId, { date: filterPeriod }, category, {
          key,
          leaf: field,
        });
        allFilterValues = Object.keys(response?.[category]?.[field]?.[key] ?? {});
      } else {
        response = await getActivityLookupLeaf(organizationId, { date: filterPeriod }, category, { key });
        allFilterValues = Object.keys(response?.[category]?.[key] ?? {});
      }
    } else {
      response = await getActivityLookupLeaf(organizationId, { date: filterPeriod }, key);
      allFilterValues = Object.keys(response[key] || {});
    }

    if (type === 'object' || category === 'objects') {
      await TEObjectsService.getObjects(allFilterValues);
    }
    setReadyFields((prev) => [...prev, fieldKey]);
    setOptions((prev) => ({
      ...prev,
      [fieldKey]: allFilterValues.map((value: string) => {
        let label: string;
        if (isTag) {
          label = TagsManager.getTagLabel(value);
        } else if (fieldKey === 'duration') {
          label = toDurationDisplay(Number(value));
        } else {
          label = TEObjectsService.getObjectLabel(value);
        }

        return {
          label: label || value,
          value,
        };
      }),
    }));
    setLoading(false);
  };

  useEffect(() => {
    if (isEmpty(defaultFields)) return;

    const doGettingLookupFields = async () => {
      setLoading(true);
      await Promise.all(
        defaultFields.map((item) =>
          doGettingLookupLeaf({
            fieldKey: objectToKey({ key: item.key }),
            type: item.key === 'primaryObject' ? 'object' : undefined,
          }),
        ),
      );
      setLoading(false);
    };
    doGettingLookupFields();
  }, [defaultFields, filterPeriod]);

  useEffect(() => {
    localStorageHelper.updateFilters({
      values: filterValues,
      period: filterPeriod,
      options,
    });
  }, [filterPeriod, filterValues, options]);

  useEffect(() => {
    localStorageHelper.updateSavedFilters(savedFilters);
  }, [savedFilters]);

  useEffect(() => {
    setReadyFields(['status']);
  }, [filterPeriod]);

  const onSaveFilter = async (name: string, settings: IFilter) => {
    setSavedFilters((previousSavedFilters) => [
      ...previousSavedFilters,
      {
        key: uuid(),
        label: name,
        filter: settings,
      },
    ]);
  };

  return (
    <div className="container--wider" data-testid="ACTIVITY_FILTERS">
      <Filter
        debounceTime={0}
        defaultFields={defaultFields}
        options={{
          status: [
            { label: 'Created', value: EActivityStatus.CREATED },
            { label: 'In review', value: EActivityStatus.IN_REVIEW },
            { label: language.constants_submitted as string, value: EActivityStatus.SUBMITTED },
          ],
          ...options,
        }}
        optionalFields={optionalFields}
        enableFreeSearch={false}
        settings={{
          filters: filterValues,
          freeTextSearch: '',
        }}
        onChange={async ({ filters, ...rest }) => {
          const newFields = Object.keys(filters).filter((fieldId) => !readyFields.includes(fieldId));
          setFilterValues(filters);
          if (newFields.length) {
            await Promise.all(
              newFields.map((_field) => {
                return doGettingLookupLeaf({ fieldKey: _field });
              }),
            );
          }
          onChange(filters);
        }}
        onSaveFilter={onSaveFilter}
        savedFilters={savedFilters}
        allowClear
      />
    </div>
  );
}
