import { ReactNode } from 'react';
import {
  TActivity2Be,
  TActivity2Fe,
} from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/Activity2.type';
import { Dayjs } from 'dayjs';
import { TWeekSelectorValue } from 'activities/components/WeekSelector/WeekSelector';

export type TActivityElementValue = any;
export type TActivityElementCategoryValue = {
  id: string | number;
  values: TActivityElementValue;
};

type TActivityValue = {
  elementId: string;
  eventId: string;
  excludeInScheduling: boolean;
  extId: string;
  rowIdx: string;
  sectionId: string;
  submissionValue: TActivityElementValue;
  submissionValueType: string;
  type: string;
  value: TActivityElementValue;
  valueMode: string;
};

export type TActivityStartEndDate = {
  startDate: string;
  endDate: string;
};

export type TActivityResultsInResponse =
  | TActivityResultsInResponseGroupByActivitySeries
  | TActivityResultsInResponseGroupByFlat;

export type TActivityResultsInResponseGroupByActivitySeries = {
  activityIds: string[];
  activityStatuses: string[]; // To be refined
  activityType: string; // To be refined
  durations: [number];
  numberOfTracks: number;
  numberOfWeeks: number;
  order: number;
  primaryObject: string;
  formId: string;
  sourceId: string;
  formInstanceId: string;
  startEndDates: TActivityStartEndDate[];
  submissionAcceptanceStatus: string; // To be refined
  activitySeriesId: string;
  allValues: { activityValue: TActivityValue }[];
  metadata: any;
  children?: TActivityResultsInResponse[];
  tags: TActivity2Fe['tags'];
};
export type TActivityResultsInResponseGroupByTrack = Omit<
  TActivityResultsInResponseGroupByActivitySeries,
  'allValues'
> & {
  trackId: number;
  allValues: TActivityValue[];
};

export type TActivityResultsInResponseGroupByFlat = TActivity2Fe;

export type TActivityRowRecord = {
  id: string;
  children?: TActivityRowRecord[];
  level?: 'series' | 'track' | 'activity';
  activitySeriesId?: string;
  trackId?: number;
};
export type TActivityRowData = Pick<
  TActivityResultsInResponseGroupByActivitySeries,
  'primaryObject' | 'activityType' | 'activitySeriesId' | 'numberOfTracks' | 'formInstanceId' | 'allValues'
> & {
  id: string;
  weeks: string;
  duration: string;
  activityReviewStatus: {
    label: string;
    color: string;
    disabled: boolean;
    icon?: ReactNode;
  };
  activityStatuses: string[];
  children?: TActivityRowData[];
  [columnExtId: string]: TActivityElementValue;
};

export type TActivityImportError = {
  cell: string;
  error: string;
  value: string;
};

export type TActivityRawValueOption = {
  value: string;
  label: string;
  isNew?: boolean;
};
export const ActivityEvents = {
  OPEN_CREATE_NEW_IMPORT_TEMPLATE_MODAL: 'OPEN_CREATE_NEW_IMPORT_TEMPLATE_MODAL',
  OPEN_IMPORT_ACTIVITIES_MODAL: 'OPEN_IMPORT_ACTIVITIES_MODAL',
  REFETCH_ACTIVITIES_TABLE: 'REFETCH_ACTIVITIES_TABLE',
  OPEN_IMPORT_RESERVATIONS_MODAL: 'OPEN_IMPORT_RESERVATIONS_MODAL',
  OPEN_ACTIVITY_DRAWER: 'OPEN_ACTIVITY_DRAWER',
  OPEN_ACTIVITY_SERIES_DRAWER: 'OPEN_ACTIVITY_SERIES_DRAWER',
  OPEN_ACTIVITY_TRACK_DRAWER: 'OPEN_ACTIVITY_TRACK_DRAWER',
  OPEN_BULK_EDIT_ACTIVITIES: 'OPEN_BULK_EDIT_ACTIVITIES,',
  CLOSE_ACTIVITY_OVERVIEW_DRAWER: 'CLOSE_ACTIVITY_OVERVIEW_DRAWER',
  CLOSE_ALL_ACTIVITY_DRAWER: 'CLOSE_ALL_ACTIVITY_DRAWER',
};

export type TActivityRawValue =
  | number
  | number[]
  | Dayjs[]
  | (number | number[])[]
  | TActivity2Be['values'][number]['value']
  | TActivityRawValueOption[]
  | TWeekSelectorValue[];
