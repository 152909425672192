import React, { ReactNode } from 'react';
import { Tooltip, Typography } from 'antd';
import intl from 'i18n/intl';

const language = intl.messages as Record<string, string>;

export function ElementValue({ hasMultipleValues, children }: { hasMultipleValues: boolean; children: ReactNode }) {
  if (hasMultipleValues)
    return (
      <Tooltip overlayClassName="test-tooltip" title={`* ${language['activities.value.multiple_values']}`}>
        <div className={'te-flex te-gap-x-2 te-items-center'} style={{ fontStyle: 'italic' }}>
          {hasMultipleValues ? <Typography.Text>*</Typography.Text> : null}
          {children}
        </div>
      </Tooltip>
    );
  return <div>{children}</div>;
}
