import React, { useEffect, useMemo, useState } from 'react';
import ActivityDrawerWrapper from './ActivityDrawerWrapper';
import { ActivityInfo, ActivityNumberOfTracks } from './ActivityDrawerComponents';
import ActivityValues, { ActivityTracks } from './ActivityValues';
import { useActivityDrawerManagement, useActivityGetter } from './ActivityDrawer.hook';
import { Button, Popconfirm } from 'antd';
import intl from 'i18n/intl';
import { deleteActivitiesById, updateActivitiesValues } from 'activities/services/activities.service';
import { ActivityEvents } from 'activities/pages/types/activity.type';
import ActivityTags from './ActivityTags';
import { DeleteOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { organizationIdSelector } from 'slices/auth.slice';
import { EActivityStatus } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityStatus.enum';
import { toActivityWeekRange } from 'activities/utils';
import ActivityWeeks from './ActivityWeeks';
import { EActivityGroupings } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityGroupings.enum';
import TEObjectsService from 'activities/services/TEObjects.service';
import { startAndEndToWeeks } from '../Table/ActivitiesTable.utils';
import { ESocketUseCase } from 'activities/pages/slices/activity.slice';
import { SeverityWarningIcon } from 'components/Icons';

const language = intl.messages as Record<string, string>;

function ActivityDrawer() {
  const [saving, setSaving] = useState(false);
  const { open, onOpen, onClose } = useActivityDrawerManagement();
  const [activityId, setActivityId] = useState<undefined | string>(undefined);

  const { loading, activity, initialValues, activitiesInSameTracks } = useActivityGetter({ activityId: activityId! });

  const organizationId = useSelector(organizationIdSelector);
  const editable = useMemo(() => {
    if (!activity) return false;
    return ![EActivityStatus.IN_REVIEW].includes(activity?.activityStatus);
  }, [activity]);

  const onSave = async (updatedValues: Record<string, string>) => {
    if (!activity || !activity.formId) return;
    setSaving(true);
    await updateActivitiesValues({
      formId: activity.formId.toString(),
      ids: [activity._id.toString()],
      groupedBy: EActivityGroupings.FLAT,
      values: updatedValues,
      timezone: activity.timezoneInfo?.identifier,
    });
    setSaving(false);
    onClose();
  };

  const onOpenCallback = (e: Event) => {
    onOpen(() => {
      setActivityId((e as CustomEvent<{ activityId: string }>).detail?.activityId);
    });
  };
  useEffect(() => {
    document.addEventListener(ActivityEvents.OPEN_ACTIVITY_DRAWER, onOpenCallback);
    return () => {
      document.removeEventListener(ActivityEvents.OPEN_ACTIVITY_DRAWER, onOpenCallback);
    };
  }, []);

  useEffect(() => {
    if (!open) {
      setActivityId(undefined);
      document.dispatchEvent(new CustomEvent(ActivityEvents.CLOSE_ALL_ACTIVITY_DRAWER));
    }
  }, [open]);

  const onDeleteConfirm = async () => {
    if (organizationId && activity?._id) {
      await deleteActivitiesById(organizationId, [activity?._id.toString()]);
      onClose();
    }
  };

  const disabledWeeks = useMemo(() => {
    if (!activitiesInSameTracks) return [];
    return activitiesInSameTracks.flatMap((activity) => {
      return toActivityWeekRange(activity.metadata.startDate, activity.metadata.endDate);
    });
  }, [activitiesInSameTracks]);

  const weekDisplay = useMemo(() => {
    if (!activity?.metadata) return '';
    return startAndEndToWeeks(activity?.metadata);
  }, [activity?.metadata]);

  return (
    <ActivityDrawerWrapper
      onClose={onClose}
      open={open}
      title={
        activity
          ? `${[
              TEObjectsService.getObjectLabel(activity.metadata.primaryObject),
              TEObjectsService.getObjectLabel(activity.metadata.activityType),
            ].join(' - ')} - week ${weekDisplay}`
          : 'N/A'
      }
      subtitle={language.activity}
      initialValues={initialValues}
      primaryObject={activity?.metadata?.primaryObject}
      editable={editable}
      loading={loading}
      saving={saving}
      onSave={onSave}
      refreshCurrentViewBoxAndLogicProps={{
        useCase: {
          socketUseCase: ESocketUseCase.singleActivityDrawer,
          activityId,
          activitySeriesId: activity?.activitySeriesGroup?.activitySeriesId,
        },
      }}
    >
      <>
        <ActivityInfo editable={false} loading={loading} activityType={activity?.metadata.activityType} />
        <ActivityNumberOfTracks totalTracks={activity?.metadata?.totalTracks} />
        <ActivityValues
          editable={editable}
          loading={loading}
          values={activity?.values}
          track={activity?.metadata?.track}
          title={activity ? `Track ${activity.track}, week ${weekDisplay} info` : 'N/A'}
        >
          {editable && (
            <div className="te-mb-2">
              <Popconfirm
                title={language['activities.confirm_to_delete_activity']}
                trigger={['click']}
                onConfirm={onDeleteConfirm}
                icon={<SeverityWarningIcon alt={language['activities.confirm_to_delete_activity']} />}
              >
                <Button size="small">
                  <DeleteOutlined /> {language.delete_activity}
                </Button>
              </Popconfirm>
            </div>
          )}

          <ActivityTracks track={activity?.metadata.track} />
          <ActivityWeeks
            editable={editable}
            required
            allowClear={false}
            disabledWeeks={disabledWeeks}
            name="dateRanges"
            editButtonText={language.change}
          />
        </ActivityValues>
        <ActivityTags formId={activity?.formId.toString()} editable={editable} />
      </>
    </ActivityDrawerWrapper>
  );
}

export default ActivityDrawer;
