import React, { ReactNode, useCallback } from 'react';
import { TActivity2Be } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/Activity2.type';
import { Divider, Input, Skeleton } from 'antd';
import { FormItem, FormItemController } from '../BaseElements/TEFormItem';
import Duration from '../BaseElements/Duration';
import TEObjectsService from 'activities/services/TEObjects.service';
import DatasourceSelectorV3 from 'components/DatasourceSelectorV3';
import ActivityValueCell from '../Table/ActivityValueCell';
import intl from 'i18n/intl';
import { ElementValue } from './ElementValue';
import { getMultipleValuesDisplayValue } from 'activities/utils';

const language = intl.messages as Record<string, string>;

type TActivityValuesProps = {
  track?: number;
  values?: TActivity2Be['values'];
  allValues?: TActivity2Be['values'];
  loading?: boolean;
  title?: string;
  editable?: boolean;
  multipleValuesExtIds?: string[];
  children?: ReactNode;
  formId?: null | string;
  timingDisabled?: boolean;
};

function ActivityFieldValue({
  extId,
  hasMultipleValues,
}: TActivity2Be['metadata']['field'][number] & { hasMultipleValues: boolean }) {
  return (
    <FormItem label={TEObjectsService.getFieldLabel(extId)}>
      <ElementValue hasMultipleValues={hasMultipleValues}>
        <FormItem name={extId}>
          <Input size="small" />
        </FormItem>
      </ElementValue>
    </FormItem>
  );
}

function ActivityObjectValue({
  extId,
  submissionType,
  hasMultipleValues,
  multipleValuesRenderer,
}: TActivity2Be['metadata']['object'][number] & { hasMultipleValues: boolean }) {
  return (
    <FormItem name={extId} label={TEObjectsService.getObjectTypeLabel(extId)}>
      <ElementValue hasMultipleValues={hasMultipleValues}>
        <FormItemController name={extId}>
          {({ value, onChange, touched }) => {
            const { value: elementValue, filters, valueType } = (value as any) || {};
            if (elementValue || filters) {
              return (
                <DatasourceSelectorV3
                  allowMultiple
                  value={elementValue as string[]}
                  filters={filters}
                  onChange={({ value, filters }) => onChange({ value, filters, valueType })}
                  typeExtId={extId}
                  type={submissionType}
                  hasMultipleValues={!touched && hasMultipleValues}
                  multipleValuesRenderer={!touched && multipleValuesRenderer}
                />
              );
            }
            return (
              <DatasourceSelectorV3
                allowMultiple
                value={value as string[]}
                typeExtId={extId}
                onChange={onChange}
                hasMultipleValues={!touched && hasMultipleValues}
                multipleValuesRenderer={!touched && multipleValuesRenderer}
              />
            );
          }}
        </FormItemController>
      </ElementValue>
    </FormItem>
  );
}

export function ActivityTracks({ track }: { track?: number }) {
  if (track === undefined) return null;
  return <FormItem label={language.track}>#{track}</FormItem>;
}

function ActivityValues(props: TActivityValuesProps) {
  const { loading, title, values, allValues, editable, children, multipleValuesExtIds, timingDisabled } = props;

  const multipleValuesRenderer = useCallback(
    (extId: string) => {
      return getMultipleValuesDisplayValue(allValues, extId);
    },
    [allValues],
  );

  return (
    <div data-testid="ACTIVITY_VALUES_IN_DRAWER">
      {title && <Divider orientation="left">{title}</Divider>}

      <Skeleton loading={loading}>
        {children}

        <FormItem label={language.duration}>
          <ElementValue hasMultipleValues={!!multipleValuesExtIds?.includes('duration')}>
            <FormItemController
              name="duration"
              rules={[
                {
                  validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error())),
                },
              ]}
              type="number"
            >
              {({ value, onChange }) => (
                <Duration value={value as number} onChange={onChange} disabled={!editable || timingDisabled} />
              )}
            </FormItemController>
          </ElementValue>
        </FormItem>

        {values?.map((val) => {
          const hasMultipleValues = multipleValuesExtIds?.includes(val.extId);
          if (!editable) {
            return (
              <FormItem
                label={TEObjectsService.getObjectTypeLabel(val.extId, TEObjectsService.getFieldLabel(val.extId))}
                key={val.extId}
              >
                <ActivityValueCell activityValue={val.value} extId={val.extId} allValues={allValues} />
              </FormItem>
            );
          }

          switch (val.type) {
            case 'object': {
              return (
                <ActivityObjectValue
                  key={val.extId}
                  editable
                  extId={val.extId}
                  submissionType={val.submissionValueType}
                  hasMultipleValues={hasMultipleValues}
                  multipleValuesRenderer={multipleValuesRenderer}
                />
              );
            }
            case 'field':
              return <ActivityFieldValue key={val.extId} extId={val.extId} hasMultipleValues={hasMultipleValues} />;
            default:
              return <ActivityFieldValue key={val.extId} extId={val.extId} hasMultipleValues={hasMultipleValues} />;
          }
        })}
      </Skeleton>
    </div>
  );
}

export default ActivityValues;
