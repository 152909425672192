/* Socket connection to pathway-service is done in socket.service.ts. This file is for socket connection to AM - which follows a somewhat different logic */
/* The file is largely copied here from am-fe */

/* eslint-disable import/no-unused-modules */
import React, { useEffect, useRef } from 'react';

import socketIOClient, { Socket } from 'socket.io-client';
import { useSelector } from 'react-redux';

// REDUX
import { organizationIdSelector } from 'slices/auth.slice';

import {
  ESocketEvent,
  TDefaultSocketPayload,
  WEBSOCKET_UPDATE,
} from '@timeedit/activity-manager-shared-lib/lib/internal/types/WebSocket.type';
import { getAccessToken } from '@timeedit/ui-components/lib/src/components/AuthWrapper/utils/tokenUtils';
import { configService } from './config.service';

export type TAMSocketEventMap = Record<ESocketEvent, Record<string, (payload: TDefaultSocketPayload) => void>>;

export interface IAMSocketContext {
  socket: Socket | undefined;
  setEventMap: (eventMap: Partial<TAMSocketEventMap>) => void;
  removeEventMap: (eventMap: Partial<TAMSocketEventMap>) => void;
  isConnected: boolean;
}

/**
 * Returns true if a disconnect reason should lead to a reconnect
 * @param disconnectReason
 * @returns {Bool}
 */
const shouldTryReconnect = (disconnectReason: Socket.DisconnectReason): boolean => {
  const VALID_RECONNECT_REASONS = ['io server disconnect'] as Socket.DisconnectReason[];
  return VALID_RECONNECT_REASONS.includes(disconnectReason);
};

const initializeAMSocketConnection = (organizationId: string) => {
  // Get the URL
  const url = configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL;
  const token = getAccessToken();

  // Initialize connection
  const socket: Socket = socketIOClient(url, {
    query: { organizationId },
    extraHeaders: token ? { Authorization: `Bearer ${token}` } : undefined,
  });

  // Set up default event handlers
  socket.on('connect', () => {
    console.info(`Websocket connection established to Activity Manager BE`);
  });

  socket.on('connect_error', (error) => {
    console.error(`Websocket connection failed to connect: `, error.toString());
    setTimeout(() => {
      if (!socket.connected) socket.io.connect();
    }, 1000);
  });

  socket.on('disconnect', (reason) => {
    console.info(`Websocket connection closed with reason: ${reason}`);
    if (shouldTryReconnect(reason)) {
      socket.io.connect();
    }
  });

  return socket;
};

export const AMSocketContext = React.createContext<IAMSocketContext>({
  socket: undefined,
  setEventMap: () => {},
  removeEventMap: () => {},
  isConnected: false,
});

export function AMWebsocketProvider({ children }: any /* TODO: Typing! */) {
  const [isConnected, setIsConnected] = React.useState(false);

  // Holds the socket ref
  const socketRef = useRef<Socket>();
  // Holds the event maps
  const eventMapRef = useRef<Partial<TAMSocketEventMap>>();

  // Holds the current organizationId
  const orgId = useSelector(organizationIdSelector);

  /**
   * Function to parse events
   */
  const parseSocketEvent = (event: TDefaultSocketPayload) => {
    for (const func of Object.values(eventMapRef?.current?.[event.type] ?? {})) {
      if (typeof func === 'function') {
        func(event);
      }
    }
  };

  /**
   * Function to set the event map
   */
  const setEventMap = React.useCallback((eventMaps: Partial<TAMSocketEventMap>) => {
    for (const [socketEvent] of Object.entries(eventMaps)) {
      const socketEventTyped = socketEvent as ESocketEvent;

      if (!eventMapRef.current) {
        eventMapRef.current = {};
      }

      eventMapRef.current[socketEventTyped] = {
        ...eventMapRef.current[socketEventTyped],
        ...eventMaps[socketEventTyped],
      };
    }
  }, []);

  const removeEventMap = React.useCallback((eventMaps: Partial<TAMSocketEventMap>) => {
    for (const [socketEvent, functionAndIdentifier] of Object.entries(eventMaps)) {
      const socketEventTyped = socketEvent as ESocketEvent;
      if (eventMapRef.current && eventMapRef.current[socketEventTyped]) {
        for (const [identifier] of Object.entries(functionAndIdentifier)) {
          if (eventMapRef.current[socketEventTyped]?.[identifier]) {
            delete eventMapRef.current[socketEventTyped]?.[identifier];
          }
        }
      }
    }
  }, []);

  /**
   * Effect to connect to BE every time we get an updated organization id
   */
  useEffect(() => {
    const onConnect = () => {
      setIsConnected(true);
    };

    const onDisconnect = () => {
      setIsConnected(false);
    };

    if (orgId) {
      // Initialize the socket connection
      socketRef.current = initializeAMSocketConnection(orgId);
      socketRef.current.off(WEBSOCKET_UPDATE, parseSocketEvent).on(WEBSOCKET_UPDATE, parseSocketEvent);

      socketRef.current.on('connect', onConnect);
      socketRef.current.on('disconnect', onDisconnect);
    }

    // Unmount
    return () => {
      socketRef?.current?.off(WEBSOCKET_UPDATE, parseSocketEvent);

      socketRef?.current?.off('connect', onConnect);
      socketRef?.current?.off('disconnect', onDisconnect);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  const contextValue = React.useMemo(
    () => ({
      socket: socketRef.current,
      setEventMap,
      removeEventMap,
      isConnected,
    }),
    [setEventMap, removeEventMap, isConnected],
  );

  return <AMSocketContext.Provider value={contextValue}>{children}</AMSocketContext.Provider>;
}
